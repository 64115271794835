import { useState } from "react";
import { useHistory } from "react-router-dom";
import headerBg from "../../../assets/header_bg.png";
import headerLeft from "../../../assets/header_left.png";
import useMediaQuery from "../../../useMediaQuery";
import GetStartedButton from "../../get-started-button/GetStartedButton";
import RegistrationForm from "../../resistration_form/RegistrationForm";
import "./Header.css";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  function openPopup() {
    history.push("/download");
  }

  let isDesktop = useMediaQuery("(min-width: 1024px)");
  let isMobileOrTablet = useMediaQuery("(max-width: 768px)");
  return (
    <section
      class="header d-flex align-items-center"
      style={{ backgroundImage: `url(${headerBg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="header-left-content">
              <span className="py-1">
                Tatsam. For your everyday life situations.
              </span>
              <p className="pt-2 pb-3">
                Care that we use for ourselves. Personalised. Holistic.
                Empathetic.
              </p>
              <div style={{ width: "100%", height: "auto" }}>
                <GetStartedButton onTap={openPopup} />
              </div>
            </div>
          </div>
          <div className="col-lg-6 header-img">
            <img src={headerLeft} alt="" />
          </div>
        </div>
      </div>
      {isOpen && <RegistrationForm handleClose={openPopup} />}
    </section>
  );
}

export default Header;
