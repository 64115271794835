function email_identifier({ email }) {
  window.analytics.identify(
    email,
    {
      email: email,
    },
    {
      integrations: {
        all: true,
        MailChimp: false,
      },
    }
  );
}

export default email_identifier;
