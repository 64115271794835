import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import ReactDOM from 'react-dom';
import App from './App';
import './fonts/fontstyle.css';
import './index.css';


Sentry.init({
  dsn: process.env.REACT_APP_TATSAM_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_TATSAM_SENTRY_SAMPLE_RATE ?? 1.0,
});

ReactDOM.render(
  <App />,
  document.getElementById('root')
);


