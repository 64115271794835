import formateDate from "../common_functions/formateDate";
import trackEvent from "../common_functions/tract_event";

function practitioner_booking_success({
  sessionId,
  contactInfo,
  startTime,
  endTime,
  date,
  practitionerId,
  practitionerName,
  practitionerType,
  amountPaid,
  isB2BUser,
  orgId,
}) {
  trackEvent({
    eventName: "Practitioner Session Booked",
    eventDetail: {
      source: "Tatsam Website",
      sessionId: sessionId,

      contactInfo: contactInfo,

      timeSlot: `${startTime.slice(0, -3)}-${endTime.slice(0, -3)}`,

      date: formateDate(date),

      practitionerId: practitionerId,

      practitionerName: practitionerName,

      practitionerType: practitionerType,
      isB2BUser: isB2BUser,
      orgId: orgId,

      amountPaid: amountPaid,
    },
  });
}

export default practitioner_booking_success;
