import axios from "axios";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import appStoreBtn from "../.././assets/appstore-btn.svg";
import mood from "../.././assets/mood.png";
import playStoreBtn from "../.././assets/playstore-btn.svg";
import RightLeaf from "../.././assets/right_flower.png";
import logo from "../../assets/logo.svg";
import addDays from "../../common_functions/addDays";
import getDate from "../../common_functions/get_date";
import getTime from "../../common_functions/get_time";
import makeUrl from "../../common_functions/makeUrl";
import email_identifier from "../../events/email_identifier";
import practitioner_booking_initialted_event from "../../events/practitioner_booking_initialted_event";
import practitioner_booking_payment_fail_event from "../../events/practitioner_booking_payment_fail_event";
import practitioner_booking_payment_initiated_event from "../../events/practitioner_booking_payment_initiated_event";
import practitioner_booking_payment_successful_event from "../../events/practitioner_booking_payment_successful_event";
import practitioner_booking_success from "../../events/practitioner_booking_success";
import "./BookingScreen.css";

const datePlaceHolder = "Select Date";

function BookingScreen() {
  const [mobileNumber, setMobileNumber] = useState(
    localStorage.getItem("mobile_number")
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("access_token")
  );
  const [showTimeSlot, setShowTimeSlot] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [selectDate, setSelectDate] = useState(datePlaceHolder);
  const [timeSlots, setTimeSlots] = useState([]);
  const [practitioner, setPractitioner] = useState();
  const [practitionerLoading, setPractitionerLoading] = useState(true);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [loading, setLoading] = useState(true);
  const [orderDataloading, setOrderDataLoading] = useState(true);
  const [slotLoading, setSlotLoading] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [orderConfirmationDataLoading, setOrderConfirmationDataLoading] =
    useState(false);
  // const [orderData, setOrderData] = useState();

  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [orderConfirmedData, setOrderConfirmedData] = useState();
  const [paymentDone, setPaymentDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [bookButton, setBookButton] = useState("Submit");
  const [inputField, setInputField] = useState({
    email: "",
  });
  const { id } = useParams();
  const navigate = useHistory();

  useEffect(() => {
    getPractitionerById();
  }, []);

  if (!accessToken) {
    navigate.replace(`/therapy/our-therapists/${id}/therapist`);
    return <div />;
  }

  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  function tConvert(timeSlots) {
    var start = timeSlots.date + "T" + timeSlots.startTime + "+05:30";
    var newDate = new Date(start).toLocaleTimeString("en-IN", {
      hour: "numeric",
      hour12: true,
    });
    return newDate;
  }

  const inputsHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputField({ ...inputField, [name]: value });
  };

  function getPractitionerById() {
    axios
      .get(makeUrl(`/practitioners/get/${id}`))
      .then((responce) => responce.data)
      .then((data) => setPractitioner(data))
      .then(() => setPractitionerLoading(false));
  }
  function getTimeSlot(date) {
    const selectedDate = new Date(date.setHours(0, 0, 0, 0));
    console.log(selectedDate.toISOString());
    axios({
      url: makeUrl(`/practitioners/hours/by-practitioner/${id}`),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({ date: selectedDate.toISOString() }),
    })
      .then((response) => response.data)
      .then((data) => setTimeSlots(data))
      .then(() => {
        setLoading(false);
        setSlotLoading(true);
      });
  }

  async function orderCreation() {
    const startTime = new Date(
      selectedTimeSlot.date + "T" + selectedTimeSlot.startTime + "+05:30"
    );

    return axios({
      url: makeUrl("/practitioners/sessions/orders"),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${accessToken}`,
      },
      // Attaching the form data
      data: JSON.stringify({
        practitionerId: id,
        startTime: startTime,
        endTime: "",
        contactNumber: mobileNumber,
        email: inputField.email,
      }),
    }).then((response) => response.data);
  }

  async function makePayment(orderData) {
    return new Promise((resolve, reject) => {
      var options = {
        key: process.env.REACT_APP_TATSAM_RAZORPAY_KEY,
        key_secret: process.env.REACT_APP_TATSAM_RAZORPAY_SECRET_KEY,
        name: "Tatsam",
        description: orderData.description,
        order_id: orderData.razorpayOrder.razorpayOrderId,
        handler: function (response) {
          const paymentInfo = {
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          if (response.razorpay_payment_id != null) {
            resolve(paymentInfo);
          } else {
            console.log("payment failed");
            reject(false);
          }
        },
        modal: {
          ondismiss: function () {
            console.log("close popup");
          },
        },
        prefill: {
          email: inputField.email,
          contact: mobileNumber,
        },
      };
      var razorPay = new window.Razorpay(options);
      razorPay.on("payment.failed", function (response) {
        console.log(response.error.code);
        console.log(response.error.description);
        console.log(response.error.source);
        console.log(response.error.step);
        console.log(response.error.reason);
        console.log(response.error.metadata.order_id);
        console.log(response.error.metadata.payment_id);
        console.log(response.error.description);
        setBookButton("Retry");
        practitioner_booking_payment_fail_event({
          orderId: orderData.id,
          amount: orderData.payableAmount.amount,
          currency: orderData.payableAmount.currency,
          description: orderData.description,
          reason: response.error.description,
        });
      });
      razorPay.open();
    });
  }

  // api call for practitioner order confirmation
  async function orderConfirmation(paymentInfo, orderData) {
    const startTime = new Date(
      selectedTimeSlot.date + "T" + selectedTimeSlot.startTime + "+05:30"
    );
    return axios({
      url: makeUrl("/practitioners/sessions/confirm"),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({
        paymentInfo: paymentInfo,
        bookingInfo: {
          practitionerId: id,
          startTime: startTime,
          endTime: "",
          contactNumber: mobileNumber,
          email: inputField.email,
        },
        orderId: orderData.id,
      }),
    }).then((response) => response.data);
  }

  //on click submit button
  async function onSubmit() {
    if (!re.test(inputField.email)) {
      setErrorMessage("Enter a valid email");
      return;
    }

    if (selectedTimeSlot == null) {
      setErrorMessage("Please select a time slot");
      return;
    }
    email_identifier({ email: inputField.email });
    const orderData = await orderCreation();
    practitioner_booking_initialted_event({
      contactInfo: mobileNumber,
      startTime: selectedTimeSlot.startTime,
      endTime: selectedTimeSlot.endTime,
      date: startDate,
      practitionerId: practitioner.id,
      practitionerName: practitioner.name,
      practitionerType: practitioner.category.name,
      isB2BUser: false,
      orgId: process.env.REACT_APP_TATSAM_ORGANIZATION_ID,
      amountToBePaid: `${orderData.payableAmount.amount} ${orderData.payableAmount.currency}`,
    });
    practitioner_booking_payment_initiated_event({
      orderId: orderData.id,
      amount: orderData.payableAmount.amount,
      currency: orderData.payableAmount.currency,
      description: orderData.description,
      retryCount: "1",
    });
    const paymentInfo = await makePayment(orderData);
    practitioner_booking_payment_successful_event({
      orderId: orderData.id,
      amount: orderData.payableAmount.amount,
      currency: orderData.payableAmount.currency,
      description: orderData.description,
    });
    const orderConfirmedData = await orderConfirmation(paymentInfo, orderData);
    setOrderConfirmedData(orderConfirmedData);
    practitioner_booking_success({
      sessionId: orderConfirmedData.id,
      contactInfo: mobileNumber,
      startTime: selectedTimeSlot.startTime,
      endTime: selectedTimeSlot.endTime,
      date: startDate,
      practitionerId: practitioner.id,
      practitionerName: practitioner.name,
      practitionerType: practitioner.category.name,
      isB2BUser: false,
      orgId: process.env.REACT_APP_TATSAM_ORGANIZATION_ID,
      amountPaid: `${orderData.payableAmount.amount} ${orderData.payableAmount.currency}`,
    });
    setOrderConfirmed(true);
  }

  //time slot pill handler
  function handleClick(i) {
    const currentClass = document.getElementsByClassName("time-slot-pill");
    for (var j = 0; j < currentClass.length; j++) {
      if (j == i) {
        currentClass[i].classList.add("active");
      } else {
        currentClass[j].classList.remove("active");
      }
    }
  }
  //style for date picker
  const MyContainer = ({ className, children }) => {
    return (
      <div
        style={{
          marginTop: "16px",
          background: "rgba(53, 66, 92, 1)",
          color: "#fff",
          maxHeight: showCalendar ? "500px" : "0",
          display: showCalendar ? "flex" : "none",
        }}
      >
        <container className={className}>
          <div style={{ position: "relative" }}>{children}</div>
        </container>
      </div>
    );
  };

  return (
    <section className="booking-page">
      <div className="container">
        <Link class="navbar-brand" to="/">
          <img src={logo} alt="" className="logo" />
        </Link>
        {orderConfirmed ? (
          <BookingSuccessComponent
            sessionName={practitioner.category.name}
            name={practitioner.name}
            time={orderConfirmedData.zohoBooking.startTime}
          />
        ) : (
          <div className="booking-content">
            <div className="booking-content-box">
              <h1>Enter your email and pick a date and time!</h1>
              <div className="booking-container">
                <div className="text-field">
                  <input
                    type="email"
                    value={inputField.email}
                    name="email"
                    onChange={inputsHandler}
                    placeholder="Enter your email.."
                    required
                  />
                </div>
                <div
                  className={
                    showCalendar ? "calendar-tile active" : "calendar-tile"
                  }
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  <span className="title">{selectDate}</span>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      getTimeSlot(date);
                      setShowTimeSlot(true);
                      setSlotLoading(false);
                      setSelectDate(getDate(`${date}`));
                    }}
                    minDate={addDays(new Date(), 3)}
                    inline
                    calendarContainer={MyContainer}
                  />
                </div>
                <div className="time-slot-tile">
                  <div
                    className="text-tile"
                    onClick={() => setShowTimeSlot(!showTimeSlot)}
                  >
                    <span className="title">Select Time</span>
                    <MdKeyboardArrowDown style={{ color: "#ABAEB2" }} />
                  </div>
                  <div
                    className={
                      showTimeSlot ? "time-slots active" : "time-slots"
                    }
                  >
                    {loading && selectDate !== datePlaceHolder && (
                      <p style={{ color: "rgba(171, 174, 178, 1)" }}>
                        Fetching available time slots...
                      </p>
                    )}
                    {loading && selectDate === datePlaceHolder && (
                      <p style={{ color: "rgba(171, 174, 178, 1)" }}>
                        Please select a date first
                      </p>
                    )}
                    {timeSlots.length == 0
                      ? !loading && (
                        <p style={{ color: "rgba(171, 174, 178, 1)" }}>
                          no slots found
                        </p>
                      )
                      : timeSlots.map((data, index) => {
                        return (
                          <div
                            className="time-slot-pill"
                            onClick={() => {
                              handleClick(index);
                              setSelectedTimeSlot(data);
                            }}
                          >
                            <p>{slotLoading ? tConvert(data) : "..."}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              {(
                <div style={{ width: "100%", marginTop: "12px", minHeight: "24px" }}>
                  <span className="error-msg" style={{ color: "red" }}>
                    {errorMessage}
                  </span>
                </div>
              )}
              <div className="bottom-button-tile">
                {!practitionerLoading && (
                  <span className="privacy-text">
                    Get started with <a>{practitioner.sessionFee.amount}</a>/-
                    per session
                  </span>
                )}
                <a
                  className="get-started-btn"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  {bookButton}
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="right-flower">
        <img src={RightLeaf} alt="" />
      </div>
      <div className="right-cirlce"></div>
      <div className="top-cirlce"></div>
      <div className="bottom-cirlce"></div>
    </section>
  );
}

function BookingSuccessComponent(props) {
  var date = getDate(props.time);
  var time = getTime(props.time);

  return (
    <div className="booking-success">
      <div className="order-detail-component">
        <h1 className="awesome-text">
          Awesome!
          <img src={mood} alt="" />
        </h1>
        <p>
          You are done! we will call you as soon as <br /> possible to set up an
          appointment!
        </p>
        <span className="text">Here are your session’s details:</span>
        <div className="booking-detail">
          <BookingDetailTile
            title={"Session:"}
            subTitle={props.sessionName}
            timeData={""}
          />
          <div className="verticle-divider"></div>
          <BookingDetailTile
            title={"Practitioner:"}
            subTitle={props.name}
            timeData={""}
          />
          <div className="verticle-divider"></div>
          <BookingDetailTile
            title={"Time and Date:"}
            subTitle={date}
            timeData={time}
          />
        </div>
        <div className="download-btn">
          <a
            href="https://apps.apple.com/us/app/1573537207"
            target="_blank"
            style={{ margin: "0 4px" }}
          >
            <img src={appStoreBtn} alt="" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=in.tatsam.app"
            target="_blank"
            style={{ margin: "0 4px" }}
          >
            <img src={playStoreBtn} alt="" />
          </a>
        </div>
        <h3>Download the app</h3>
      </div>
    </div>
  );
}

function BookingDetailTile(props) {
  return (
    <div className="detail-tile">
      <span className="title">{props.title}</span>
      <span className="subTitle">{props.subTitle}</span>
      <span className="subTitle">{props.timeData}</span>
    </div>
  );
}

export default BookingScreen;
