export default [
  {
    answer:
      "Coming from elite institutions, years of training under eminent psychologists and academicians, Tatsam therapists undergo a rigorous in-house licensing program.",
  },
  {
    answer:
      "They help clients develop emotional and cognitive coping skills to help reduce the severity of their symptoms over time.",
  },
  {
    answer:
      "They are trained in a variety of evidence-based practices and further hone their therapy skills.",
  },
  {
    answer:
      "They provide clients with behavioral health tools, strategies, and education aimed at improving their mental health symptoms.",
  },
  {
    answer:
      "They can also work in tandem with other providers (psychiatrists, yoga therapists, nutrition coaches etc. ) to ensure that your treatment plan is working effectively. ",
  },
];
