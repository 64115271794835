import identifyEvent from "../common_functions/identify_event.jsx";

function user_login_event({ phone, user }) {
  identifyEvent({
    identifier: user.sub,
    detail: {
      phone: phone,
    },
  });
}

export default user_login_event;
