import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Rfbg from "../../assets/r-f.png";
import "./RegistrationForm.css";

function RegistrationForm(props) {
  function resetValue() {
    inputField.email = "";
  }
  const [inputField, setInputField] = useState({
    email: "",
  });
  const [inputErrorMessage, setInputErrorMessage] = useState("");

  const inputsHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputField({ ...inputField, [name]: value });
    setInputErrorMessage("");
  };
  const submit = () => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(inputField.email)) {
      onSubmit(inputField.email);
      setTimeout(() => {
        props.handleClose();
      }, 500);
    } else {
      setInputErrorMessage("!Please Enter Valid Email");
    }
  };
  const onSubmit = (email) => {
    window.analytics.identify(email, {
      integrations: {
        all: true,
      },
    });

    window.analytics.track(
      "App Access Requested",
      {
        source: "Tatsam Website",
        email: email,
      },
      {
        integrations: {
          all: true,
        },
      }
    );
  };
  return (
    <div className="resistration-form">
      <div className="layer" onClick={props.handleClose}></div>
      <div
        className="form"
        data-aos="zoom-in"
        data-aos-duration="400"
        style={{
          backgroundImage: `url(${Rfbg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="local-form">
            <span>Your FREE access awaits</span>
            <p>
              Share your email and we'll send a code for a free access and
              instructions for how to sign up to your inbox.{" "}
            </p>
            <div className="input-field">
              <input
                type="email"
                placeholder="example@gmail.com"
                name="email"
                value={inputField.email}
                onChange={inputsHandler}
                required
              />
              <span className="error-msg">{inputErrorMessage}</span>
            </div>

            <a onClick={submit} class="get-started-btn">
              Submit
            </a>
          </div>
          <AiOutlineCloseCircle
            onClick={props.handleClose}
            style={{
              fontSize: "35px",
              position: "absolute",
              top: "15px",
              right: "15px",
              color: "rgba(36, 121, 168, 1)",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default RegistrationForm;
