import appStoreBtn from "../../assets/appstore-btn.svg";
import tatsamQr from "../../assets/DownloadPageAssets/tatsam-qr.png";
import playStoreBtn from "../../assets/playstore-btn.svg";
import Footer from "../home_page/footer/Footer";
import Evidence from "../home_page/header/Evidence";
import Navbar from "../navbar/Navbar";
import "./DownloadPage.css";

function DownloadPage() {
  return (
    <div>
      <Evidence bgColor="rgba(62, 150, 86, 1)" />
      <Navbar />
      <div className="container">
        <div className="download-page">
          <span className="download-page-title">
            <h1>
              Find our app on the app store &<br />
              the play store!
            </h1>
          </span>
          <br />
          <img class="qrcode" src={tatsamQr} alt="" />
          <br />
          <span className="download-page-sub-title">
            <h1>
              <b>Click to download tatsam app</b>
            </h1>
          </span>
          <div className="">
            <a
              className="appStoreBtn"
              href="https://apps.apple.com/us/app/1573537207"
              target="_blank"
            >
              <img src={appStoreBtn} id="appStoreBtn" alt="" />
            </a>

            <a
              className="playStoreBtn"
              href="https://play.google.com/store/apps/details?id=in.tatsam.app"
              target="_blank"
            >
              <img src={playStoreBtn} id="playStoreBtn" alt="" />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default DownloadPage;
