import React from "react";
import "./OurPartner.css";
import ourPartner from "../../../assets/our_partner.png";
import useMediaQuery from '../../../useMediaQuery';

function OurPartner() {
  let isDesktop=useMediaQuery('(min-width: 1024px)');
  let isMobileOrTablet=useMediaQuery('(max-width: 768px)');
  return (
    <div className="our-partner">
      <div className="container"><img src={ourPartner} alt="" /></div>
      <div className="our-partner-content">
        
        {isDesktop && <p>
          Tatsam uses a proprietary approach which combines evidence-based
          practices from eastern and western wellbeing<br/> tailored specifically for
          modern working professionals.
        </p>}
        {isMobileOrTablet && <p>
          Tatsam uses a proprietary approach which combines evidence-based
          practices from eastern and western wellbeing tailored specifically for
          modern working professionals.
        </p>}
        
      </div>
    </div>
  );
}

export default OurPartner;
