export default [
  {
    imgUrl:
      "https://images.unsplash.com/photo-1629708494720-91f2c75f7604?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
    content: "“I feel trapped at times”",
    age: "30 years Male",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1623091411466-19dce1ed99a3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGluZGlhbiUyMGZlbWFsZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60",
    content: "“I don’t want to be responsible”",
    age: "32 years Female",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1639591903821-9b5e38f97bbd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    content: "“I just want to be happy”",
    age: "20 years Female",
  },
  {
    imgUrl:
      "https://images.unsplash.com/photo-1605789324372-e2d18680046c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fG11bWJhaSUyMG1hbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60",
    content: "“I want to be at the top of my career”",
    age: "26 years Male",
  },
];
