import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "react-phone-input-2/lib/style.css";
import { useHistory } from "react-router-dom";
import identifyEventForMailChimps from "../../common_functions/identifyEventForMailChimps";
import identifyEvent from "../../common_functions/identify_event";
import "./UnderStandingSession.css";

function UnderStandingSession(props) {
  const [inputField, setInputField] = useState({
    email: "",
    phone: "",
    textArea: "",
  });
  const [phone, setPhone] = useState();
  const [validNumber, setValidNumber] = useState(true);
  const [dialCode, setDialCode] = useState("");

  const history = useHistory();
  function routeChange() {
    history.push("/thank-you");
  }
  function handleOnChange(value, data, event, formattedValue) {
    setDialCode(data.dialCode);
    setPhone(value);
    if (formattedValue.length === data.format.length) {
      setValidNumber(true);
    } else {
      setValidNumber(false);
    }
  }

  const [inputError, setInputError] = useState(false);
  const [textAreaErrorMessage, setTextAreaErrorMessage] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [loading, setLoading] = useState(false);

  const inputsHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputField({ ...inputField, [name]: value });
    setInputError(false);
    setTextAreaErrorMessage("");
  };

  const submit = () => {
    const phoneNumber = `+${phone}`;
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(inputField.email)) {
      if (validNumber) {
        if (inputField.textArea.length <= 250) {
          onSubmit(inputField.email, phoneNumber, inputField.textArea);
        } else {
          setTextAreaErrorMessage("your concern should be 0-250 words!");
        }
      } else {
        setPhoneNumberError("Please enter a valid phone number!");
      }
    } else {
      setInputError(true);
    }
  };
  const onSubmit = (email, phone, textArea) => {
    identifyEvent({
      identifier: email,
      detail: {
        email: email,
      },
    });

    identifyEventForMailChimps({
      identifier: email,
      detail: {
        email: email,
        phone: phone,
        concern: textArea,
      },
    });

    window.analytics.track(
      "Understanding Session Requested",
      {
        source: "tatsam website",
      },
      {
        integrations: {
          all: true,
        },
      }
    );
    setLoading(true);
    setTimeout(() => {
      props.handleClose();
      routeChange();
    }, 2000);
  };
  return (
    <div className="understanding-form">
      <div className="layer" onClick={props.handleClose}></div>
      <div className="form" data-aos="zoom-in" data-aos-duration="400">
        <div className="container">
          <div className="local-form">
            <p>
              You're one step closer to book an{" "}
              <span>exclusive Tatsam experience</span>
            </p>
            <div className="input-field">
              <h3 className="input-label">Email</h3>
              <input
                type="email"
                placeholder="Enter your email"
                name="email"
                value={inputField.email}
                onChange={inputsHandler}
                required
                style={{ border: `1px solid ${inputError ? "red" : "black"}` }}
              />
            </div>
            <div className="input-field">
              <h3 className="input-label">Phone Number</h3>
              {/* <input
                type="number"
                placeholder="Enter phone number"
                name="phone"
                value={inputField.phone}
                onChange={inputsHandler}
                pattern="[0-9]+"
                required
              /> */}
              <PhoneInput
                defaultCountry={"in"}
                country={"in"}
                enableSearch={true}
                value={phone}
                onChange={handleOnChange}
              />
              <div style={{ height: "14px" }}>
                <span
                  className="error"
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontWieght: "100",
                    width: "100%",
                  }}
                >
                  {phoneNumberError}
                </span>
              </div>
            </div>
            <div className="input-field">
              <h3 className="input-label">
                What is your key concern or reason for seeking out therapy?
              </h3>
              <textarea
                type="text"
                placeholder="Feel free to share as much information as you want"
                name="textArea"
                value={inputField.textArea}
                onChange={inputsHandler}
                required
              />
              <div style={{ height: "14px" }}>
                <span
                  className="error"
                  style={{
                    color: "red",
                    fontSize: "12px",
                    fontWieght: "100",
                    width: "100%",
                  }}
                >
                  {textAreaErrorMessage}
                </span>
              </div>
            </div>

            <a
              onClick={submit}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
              class="get-started-btn"
            >
              {loading ? (
                <div
                  className="loader"
                  style={{ height: "20px", width: "20px", textAlign: "center" }}
                ></div>
              ) : (
                "Book my session"
              )}
            </a>
          </div>
          <AiOutlineCloseCircle
            onClick={props.handleClose}
            style={{
              fontSize: "35px",
              position: "absolute",
              top: "15px",
              right: "15px",
              color: "rgba(36, 121, 168, 1)",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default UnderStandingSession;
