import "../therapist_page/TherapistsPage.css";

function PractitionerCard(props) {
  return (
    <div class="practitioner-card" onClick={props.tap}>
      <div className="gradient-box"></div>
      <img
        src={props.data.profilePhotoUrl}
        alt=""
        className="therapist-image"
      />
      <div class="content">
        <div class="contentBx">
          <h3 className="name">{props.data.name}</h3>
          <div className="divider"></div>
          <p className="specailization" style={{ padding: "0 8px" }}>
            {props.data.coverSubtitle}
          </p>
        </div>
      </div>
      <div class="sci">
        <div className="specialization-area">
          {props.data.specializations.map((data, index) => {
            if (index <= 1) {
              return (
                <a className="specialization-pill">{data.displayName}</a>
              );
            }
          })}

          {props.data.specializations.length > 2 && (
            <a className="specialization-pill">+{props.data.specializations.length - 2}</a>
          )}
          

          { }
        </div>
        <a className="get-started-btn" onClick={props.tap}>
          Book a session
        </a>
        {/* <a className="watch-video-btn" onClick={props.handleOpen}>
          Watch video <BiPlayCircle />
        </a> */}
      </div>
    </div>
  );
}

function PractitionerCardMobile() {
  return (
    <div class="practitioner-card-mob">
      <img
        src="https://images.unsplash.com/photo-1655484704419-58277e774968?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxN3x8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=60"
        alt=""
      />
      <div className="gradient-box"></div>
      <div class="contentBx">
        <h3 className="name">Kahina Lakshmi</h3>
        <div className="divider"></div>
        <p className="specailization">Therapist speciality here</p>
      </div>
    </div>
  );
}

export default PractitionerCard;
