function identifyEvent({ identifier, detail }) {
  window.analytics.identify(identifier, detail, {
    integrations: {
      all: true,
      MailChimp: false,
    },
  });
}

export default identifyEvent;
