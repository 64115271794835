import useMediaQuery from "../../useMediaQuery";

function SpecializationPill(props) {
  let isDesktop = useMediaQuery("(min-width: 1024px)");
  let isMobileOrTablet = useMediaQuery("(max-width: 768px)");
  const mystyle = {
    border: `1px solid ${props.color}`,
    borderRadius: " 16px 0px",
    padding: isDesktop ? "10px" : "8px",
    margin: "4px 4px",
    cursor: "default",
  };

  const titleStyle = {
    color: `${props.color}`,
    fontWeight: "400",
    fontSize: isDesktop ? "16px" : "14px",
    lineHeight: isDesktop ? "19px" : "16px",
    textAlign: "center",
  };
  return (
    <div style={mystyle}>
      <span style={titleStyle}>{props.title}</span>
    </div>
  );
}

export default SpecializationPill;
