import trackEvent from "../common_functions/tract_event";

function login_failed_event(e) {
  trackEvent({
    eventName: "Login Initiated",
    eventDetail: {
      source: "Tatsam Website",
      loginMethod: "phone-number",
      reason: e.toString(),
    },
  });
}

export default login_failed_event;
