import { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsPauseFill, BsPlayFill } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import "./TherapistVideoPopup.css";

function TherapistVideoPopup(props) {
  const [playing, setPlaying] = useState(true);
  const [videoTime, setVideoTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const videoRef = useRef(null);

  useEffect(() => {
    videoRef.current.play();
    setPlaying(true);
  }, [0]);

  window.setInterval(function () {
    setCurrentTime(videoRef.current?.currentTime);
    var vid = document.getElementById("video1");
    setVideoTime(vid?.duration);
  }, 1000);

  const videoHandler = (control) => {
    if (control === "play") {
      videoRef.current.play();
      setPlaying(true);
    } else if (control === "pause") {
      videoRef.current.pause();
      setPlaying(false);
    }
  };
  return (
    <div className="therapist-video-popup">
      <div className="container">
        <div className="therapist-tile">
          <div className="row1">
            <img
              alt="Avatar"
              class="therapist-avatar"
              src="https://media.istockphoto.com/photos/female-psychologist-making-notes-with-pleasure-smile-while-holding-picture-id1349926262?b=1&k=20&m=1349926262&s=170667a&w=0&h=rmTsVKEr5Ox4uuaGSMh4x5R-P994ydXNLnoCFjddS_4="
            />
            <div style={{ width: "16px" }}></div>
            <div className="col1">
              <p className="therapist-name">Chetna Luthra</p>
              <p className="therapist-role">Relationship Counselling</p>
            </div>
          </div>
          <a className="get-started-btn">
            Book a session <IoIosArrowForward />
          </a>
        </div>
        <div style={{ height: "16px" }}></div>
        <div className="video-player-component">
          <video
            className="video-player"
            id="video1"
            ref={videoRef}
            src="https://player.vimeo.com/progressive_redirect/playback/441212778/rendition/720p?loc=external&oauth2_token_id=1027659655&signature=276f5d48e2423392e80686875f86279bf5300bfe11c5a4a52fec2a5fb8899e47"
          />
          <div className="video-control-tile">
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (playing) {
                  videoHandler("pause");
                } else {
                  videoHandler("play");
                }
              }}
            >
              {playing ? (
                <BsPauseFill
                  style={{
                    fontSize: "25px",
                    marginLeft: "30px",
                    color: "black",
                  }}
                />
              ) : (
                <BsPlayFill
                  style={{
                    fontSize: "25px",
                    marginLeft: "30px",
                    color: "black",
                  }}
                />
              )}
            </a>
            <div style={{ width: "50px" }}></div>
            <p className="controlsTime">
              {Math.floor(currentTime / 60) +
                ":" +
                ("0" + Math.floor(currentTime % 60)).slice(-2)}
            </p>
            <p className="controlsTime">/</p>
            <p className="controlsTime">
              {Math.floor(videoTime / 60) +
                ":" +
                ("0" + Math.floor(videoTime % 60)).slice(-2)}
            </p>
          </div>
        </div>
      </div>
      <a className="close-button" onClick={props.handleClose}>
        <AiOutlineClose
          style={{ color: "rgba(171, 174, 178, 1)", fontSize: "35px" }}
        />
      </a>
    </div>
  );
}

export default TherapistVideoPopup;
