import Evidence from "../home_page/header/Evidence";
import Navbar from "../navbar/Navbar";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import HolisticAproach from "./holistic_aproach/HolisticAproach";
import HowItWork from "./how_does_it_work/HowItWork";
import OurPartner from "./our_partner/OurPartner";
function HomePage() {
  function checkAccessTokenStatus() {
    console.log(window.location.hash);
  }

  return (
    <div onLoad={checkAccessTokenStatus}>
      <Evidence bgColor="rgba(62, 150, 86, 1)" />
      <Navbar />
      <Header />
      <OurPartner />
      <HolisticAproach />
      <HowItWork />
      {/* <TherapistSlider /> */}
      <Footer />
    </div>
  );
}

export default HomePage;
