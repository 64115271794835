import { useState } from "react";
import { useHistory } from "react-router-dom";
import ss1 from "../../../assets/ss-1.png";
import ss2 from "../../../assets/ss-2.png";
import ss3 from "../../../assets/ss-3.png";
import useMediaQuery from "../../../useMediaQuery";
import GetStartedButton from "../../get-started-button/GetStartedButton";
import RegistrationForm from "../../resistration_form/RegistrationForm";
import "./HowItWork.css";

function HowItWork() {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  function openPopup() {
    history.push("/download");
  }

  let isDesktop = useMediaQuery("(min-width: 1024px)");
  let isMobileOrTablet = useMediaQuery("(max-width: 768px)");

  return (
    <section className="how-it-work d-flex align-items-center">
      <div className="container">
        <div className="top-content">
          <h1>How does it work?</h1>
          {isDesktop && (
            <p>
              Working professionals get fast, credible and holistic access to
              the right care drawn from a variety
              <br /> of mental health solutions.
            </p>
          )}
          {isMobileOrTablet && (
            <p>
              Working professionals get fast, credible and holistic access to
              the right care drawn from a variety of mental health solutions.
            </p>
          )}
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="app-ss">
              {isDesktop && (
                <span className="title">
                  Therapists that help <br />
                  you reveal and heal
                </span>
              )}
              {isMobileOrTablet && (
                <span className="title">
                  Therapists that help you reveal and heal
                </span>
              )}
              <img src={ss1} alt="" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="app-ss">
              {isDesktop && (
                <span className="title">
                  5 minutes quick relief to keep you on
                  <br /> top performance or just to relax.
                </span>
              )}
              {isMobileOrTablet && (
                <span className="title">
                  5 minutes quick relief to keep you on top performance or just
                  to relax.
                </span>
              )}
              <img src={ss2} alt="" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="app-ss">
              {isDesktop && (
                <span className="title">
                  Or choose your path! you can
                  <br /> personalize your healing journey!
                </span>
              )}
              {isMobileOrTablet && (
                <span className="title">
                  Or choose your path! you can personalize your healing journey!
                </span>
              )}
              <img src={ss3} alt="" />
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-12 py-5 d-flex justify-content-center">
            <GetStartedButton onTap={openPopup} />
          </div>
        </div>
      </div>
      {isOpen && <RegistrationForm handleClose={openPopup} />}
    </section>
  );
}

export default HowItWork;
