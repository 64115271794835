function indentifyEventForMailChimps({ identifier, detail }) {
  window.analytics.identify(identifier, detail, {
    integrations: {
      all: false,
      MailChimp: true,
    },
  });
}

export default indentifyEventForMailChimps;
