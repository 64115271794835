import trackEvent from "../common_functions/tract_event.jsx";

function login_initiated_event(phone) {
  trackEvent({
    eventName: "Login Initiated",
    eventDetail: {
      source: "Tatsam Website",
      loginMethod: "phone-number",
      contactInfo: phone,
    },
  });
}

export default login_initiated_event;
