import auth0 from 'auth0-js';
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

function CrossAuthComponent() {
  const { id } = useParams();
  const auth0Client = useRef(new auth0.WebAuth({
    clientID: process.env.REACT_APP_TATSAM_WEB_AUTH0_CLIENTID,
    domain: process.env.REACT_APP_TATSAM_WEB_AUTH0_DOMAIN,
    redirectUri: `${window.location.origin}/phone-verification`
  }));
  useEffect(() => {
    auth0Client.current.crossOriginVerification();
  }, [])

  return (
    <div>This is CrossAuth Page</div>
  );
}

export default CrossAuthComponent;
