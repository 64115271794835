import { IoIosArrowForward } from "react-icons/io";
import "./GetStartedButton.css";

function GetStartedButton(props) {
  return (
    <div className="get-started-button">
      <a className="get-started-btn" onClick={props.onTap}>
      Start my journey{" "}
        <IoIosArrowForward style={{ fontSize: "24px", marginBottom: "4px" }} />
      </a>
    </div>
  );
}

export default GetStartedButton;
