import React from "react";
import { BsFillCheckCircleFill, BsInstagram } from "react-icons/bs";
import { GrLinkedinOption } from "react-icons/gr";
import appStoreBtn from "../../assets/appstore-btn.svg";
import logo from "../../assets/logo.svg";
import playStoreBtn from "../../assets/playstore-btn.svg";
import "./Thankyou.css";

function Thankyou() {
  return (
    <section className="thank-you">
      <div className="container">
        <a href="index.html">
          <img src={logo} alt="" className="logo" />
        </a>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-12 d-flex content align-items-center justify-content-center flex-column">
            <BsFillCheckCircleFill
              style={{ color: "#83E0DD", fontSize: "50px" }}
            />
            <h1>
              Congratulations! You have successfully
              <br /> registered for an understanding session.
            </h1>
            <p className="follow-us-text">For more updates, follow us:</p>
            <div className="follow-btn">
              <a
                href="https://www.instagram.com/tatsam.app/"
                target="_blank"
                className="instagram"
              >
                <BsInstagram
                  style={{
                    fontSize: "40px",
                    color: "#fff",
                    background: "#E1306C",
                    border: "5px solid #E1306C",
                  }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/tatsam-wellness/mycompany/"
                target="_blank"
                className="linkedin"
              >
                <GrLinkedinOption
                  style={{
                    fontSize: "40px",
                    background: "#0077B5",
                    color: "#fff",
                    border: "5px solid #0077B5",
                  }}
                />
              </a>
            </div>
            <p>
              Thanks a bunch for filling that out. It means a lot to us, just
              like you do! We really appreciate you giving us a moment of your
              time today. Thanks for being you. We will reach out to you soon.
            </p>
            <p>
              In case you want to skip the Waitlist, drop us a "Hello" at{""}
              <a
                href="mailto: bhavna@tatsam.in"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "#2479A8",
                  fontWeight: "600",
                }}
              >
                {" "}
                bhavna@tatsam.in
              </a>
            </p>
            <div className="download-btn">
              <a
                className="appStoreBtn"
                href="https://apps.apple.com/us/app/1573537207"
                target="_blank"
              >
                <img src={appStoreBtn} id="appStoreBtn" alt="" />
              </a>

              <a
                className="playStoreBtn"
                href="https://play.google.com/store/apps/details?id=in.tatsam.app"
                target="_blank"
              >
                <img src={playStoreBtn} id="playStoreBtn" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Thankyou;
