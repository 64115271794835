import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useHash() {
  const { hash } = useLocation();

  return useMemo(() => hash);
}

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
