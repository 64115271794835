import AOS from 'aos';
import { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import BookUnderstandingPage from './components/book_understanding_page/BookUnderstandingPage';
import DownloadPage from "./components/download_page/DownloadPage";
import HomePage from './components/home_page/HomePage';
import Thankyou from './components/join_the_wait_list_stress/Thankyou';
import PrivacyPolicy from './components/privacy-policy/PrivacyPolicy';
import TermsAndCondition from './components/termsCondition/TermsAndCondition';
import BookingScreen from './components/therapist_booking_page/BookingScreen';
import MobileNumberConfirmationScreen from './components/therapist_booking_page/MobileNumberConfirmationScreen';
import TherapistDetailPage from './components/therapist_detail_page/TherapistDetailPage';
import TherapistHomePage from './components/therapist_home_page/TherapistHomePage';
import TherapistsPage from './components/therapist_page/TherapistsPage';
import CrossAuthComponent from "./crossAuth";
import ScrollToTop from './ScrollToTop';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);

  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path='/' component={HomePage}></Route>
            <Route exact path='/therapy' component={TherapistHomePage}></Route>
            <Route exact path='/therapy/our-therapists' component={TherapistsPage}></Route>
            <Route exact path='/therapy/our-therapists/:id/:name' component={TherapistDetailPage}></Route>
            <Route exact path='/therapy/our-therapists/:id/:name/book-session' component={BookingScreen}></Route>
            <Route exact path='/privacy-policy' component={PrivacyPolicy}></Route>
            <Route exact path='/terms-and-conditions' component={TermsAndCondition}></Route>
            <Route exact path='/auth0/cors-verification' component={CrossAuthComponent}></Route>
            <Route exact path='/phone-verification' component={MobileNumberConfirmationScreen}></Route>
            <Route exact path='/download' component={DownloadPage}></Route>
            <Route exact path='/thank-you' component={Thankyou}></Route>
            <Route exact path='/book-your-understanding-session/' component={BookUnderstandingPage}></Route>
            <Route path="/*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;

