import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import rightLeaf2 from "../../assets/image_6.png";
import Leaf4 from "../../assets/left_flower.png";
import leftLeaf from "../../assets/left_leaf.png";
import rightLeaf from "../../assets/right_leaf.png";
import bg from "../../assets/therapist_bg.png";
import makeUrl from "../../common_functions/makeUrl";
import shuffle from "../../common_functions/shuffle";
import useMediaQuery from "../../useMediaQuery";
import Evidence from "../home_page/header/Evidence";
import Navbar from "../navbar/Navbar";
import SpecializationPill from "../react_component/SpecializationPill";
import FAQ from "../therapist_page/FAQ";
import "./TherapistDetailPage.css";
import UnderStandingSession from "./UnderStandingSession";

function TherapistDetailPage() {
  const navigate = useHistory();
  const toDetailPage = (id, name) => {
    navigate.push(`/therapy/our-therapists/${id}/${name}`);
  };
  const redirectPage = (id, name) => {
    var therapyName = name.replaceAll(" ", "-").toLowerCase();
    navigate.replace(`/therapy/our-therapists/${id}/${therapyName}`);
  };
  const toMobileNumberScreen = (id) => {
    navigate.push(`/phone-verification?id=${id}`);
  };
  const { id } = useParams();
  const { name } = useParams();
  const [openPopup, setOpenPopup] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [practitioner, setPractitioner] = useState();
  const [practitioners, setPractitioners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPoster, setShowPoster] = useState(true);
  let isDesktop = useMediaQuery("(min-width: 1024px)");
  let isMobileOrTablet = useMediaQuery("(max-width: 768px)");
  const videoRef = useRef(null);

  useEffect(() => {
    if (
      !loading &&
      name != practitioner.name.replaceAll(" ", "-").toLowerCase()
    ) {
      redirectPage(practitioner.id, practitioner.name);
    }
  }, [practitioner, loading]);

  useEffect(async () => {
    await getPractitionerById(id);
    await getAllPractitioners();
    setLoading(false);
  }, []);

  const getPractitionerById = (id) => {
    return fetch(makeUrl(`/practitioners/get/${id}`))
      .then((responce) => responce.json())
      .then((data) => {
        setPractitioner(data);
      });
  };

  function getAllPractitioners() {
    return fetch(
      makeUrl(`/practitioners/${process.env.REACT_APP_TATSAM_CATEGORY_ID}`)
    )
      .then((responce) => responce.json())
      .then((data) => {
        setPractitioners(shuffle(data));
        console.log(data);
      });
  }
  const randomPractitioners = [];

  if (!loading) {
    practitioners.map((data, index) => {
      if (practitioner.id != data.id) {
        if (index <= 4) {
          randomPractitioners.push(data);
        }
      }
    });
  }
  // console.log(randomPractitioner);

  const videoHandler = (control) => {
    if (control === "play") {
      videoRef.current.play();
      setPlaying(true);
      setShowPoster(false);
    } else if (control === "pause") {
      videoRef.current.pause();
      setPlaying(false);
    }
  };
  function openUnderStandingPopup() {
    setOpenPopup(!openPopup);
  }
  console.log({
    loading,
    practitioner,
    practitioners,
  });
  return (
    <div className="therapist-detail-page">
      <Evidence bgColor="rgba(62, 150, 86, 1)" />
      <Navbar />
      <section className="therapist-detail-section">
        {loading ? (
          <div className="loader"></div>
        ) : (
          <div className={isDesktop && "container"}>
            <div className="therapist-intro-section">
              <div className="therapist-image-box">
                <img src={practitioner.profilePhotoUrl} alt="" />
                <div className="gradient-box"></div>
              </div>
              <div className="therapist-intro">
                <img src={rightLeaf2} alt="" className="image-6" />
                <div className="blur-layer"></div>
                <img src={bg} className="therapist-bg" alt="" />
                <div className="therapist-intro-content">
                  <span className="therapist-name">{practitioner.name}</span>
                  <div className="therapist-specialization">
                    {practitioner.specializations.map((data, index) => {
                      return (
                        <SpecializationPill
                          color="white"
                          title={data.displayName}
                        />
                      );
                    })}
                  </div>
                  <span className="therapist-bio">
                    {practitioner.biography}
                  </span>
                  <a
                    style={{ marginTop: "16px" }}
                    className="get-started-btn"
                    onClick={() => toMobileNumberScreen(practitioner.id)}
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* related and faq tabs */}

        <div className="row" style={{ width: "100%" }}>
          {isDesktop && (
            <div className="RecommendedTabs">
              <img src={leftLeaf} alt="" className="left-leaf" />
              <div className="circle-secondery-1"></div>
              <div className="container">
                <h1>Other Therapists in this speciality</h1>
                <div
                  className="row"
                  style={{
                    minHeight: "50vh",
                    width: "100%",
                    paddingTop: "50px",
                  }}
                >
                  {randomPractitioners.map((data, index) => {
                    if (index < 4) {
                      return (
                        <RecommendedTherapist
                          data={data}
                          onTap={() => {
                            toDetailPage(data.id, data.name);
                            getPractitionerById(data.id);
                          }}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          )}
          {isMobileOrTablet && (
            <div className="RecommendedTabs">
              <div className="circle-secondery-1"></div>
              <div className="container">
                <h1>Other Therapists in this speciality</h1>
                <div
                  className="row"
                  style={{
                    minHeight: "50vh",
                    width: "100%",
                    paddingTop: "50px",
                  }}
                >
                  {randomPractitioners.map((data, index) => {
                    if (index < 4) {
                      return (
                        <RecommendedTherapistMob
                          data={data}
                          onTap={() => {
                            toDetailPage(data.id, data.name);
                            getPractitionerById(data.id);
                          }}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <ExperienceTatsamWayComponent
          openUnderStandingPopup={() => openUnderStandingPopup()}
        /> */}
        <div className="container">
          {isDesktop && (
            <div
              className="row"
              style={{ paddingTop: "150px", position: "relative" }}
            >
              <img src={Leaf4} className="leaf-4" alt="" />
              <div className="circle-3"></div>
              <FAQ />
            </div>
          )}
          {isMobileOrTablet && (
            <div
              className="row"
              style={{ paddingTop: "50px", position: "relative" }}
            >
              <img src={Leaf4} className="leaf-4" alt="" />
              <div className="circle-3"></div>
              <FAQ />
            </div>
          )}
        </div>
      </section>
      {openPopup && (
        <UnderStandingSession handleClose={openUnderStandingPopup} />
      )}
    </div>
  );
}

function RecommendedTherapist(props) {
  return (
    <div onClick={props.onTap} class="recommended-practitioner-card">
      <img src={props.data.profilePhotoUrl} className="img-box" alt="" />
      <div className="gradient-box"></div>
      <div class="content">
        <div class="contentBx">
          <h3 className="name">{props.data.name}</h3>
          <div className="divider"></div>
          <p className="specailization">{props.data.coverSubtitle}</p>
        </div>
      </div>
    </div>
  );
}

function RecommendedTherapistMob(props) {
  return (
    <div class="practitioner-card-mob" onClick={props.onTap}>
      <img src={props.data.profilePhotoUrl} alt="" />
      <div className="gradient-box"></div>
      <div class="contentBx">
        <h3 className="name">{props.data.name}</h3>
        <div className="divider"></div>
        <p className="specailization">{props.data.coverSubtitle}</p>
      </div>
    </div>
  );
}

function ExperienceTatsamWayComponent(props) {
  return (
    <div className="experience-tatsam-way">
      <div className="circle-primary-1"></div>
      <img src={rightLeaf} alt="" className="right-leaf" />
      <div className="container">
        <div className="experience-content">
          <h1>
            Experience <span>tatsam way.</span>
          </h1>
          <div style={{ height: "32px" }}></div>
          <p className="experience-text">
            You can talk to our therapist for 30 mins and seek their help in
            creating a protocol/ daily routine for self-help and self-care.
          </p>
          <div style={{ height: "16px" }}></div>
          <p className="experience-text">
            You may just want an objective opinion on a difficult relationship,
            need advice on a major life decision, want someone to listen, or be
            looking for support with a mental health concern.
          </p>
          <div style={{ height: "16px" }}></div>
          <h4>
            Whatever your reason for seeking this understanding session, it is
            valid.
          </h4>
          <div style={{ height: "16px" }}></div>
          <a className="get-started-btn" onClick={props.openUnderStandingPopup}>
            Book an understanding session
          </a>
          <div style={{ height: "16px" }}></div>
          <p className="experience-text" style={{ fontSize: "14px" }}>
            Many folks like you have used these understanding sessions to come
            out of their relationship troubles, reduce stress, low mood or
            simply find answers for everyday life situations.
          </p>
        </div>
      </div>
    </div>
  );
}
export default TherapistDetailPage;
