function getTime(str) {
  var date = new Date(str.split("[")[0]);

  var hours = ("0" + date.getHours()).slice(-2);
  var minutes = ("0" + date.getMinutes()).slice(-2);
  var seconds = ("0" + date.getSeconds()).slice(-2);
  var time = [hours, minutes, seconds].join(":");

  return time;
}

export default getTime;
