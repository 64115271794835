import "../therapist_page/TherapistsPage.css";

function PractitionerCardMobile(props) {
  return (
    <div class="practitioner-card-mob" onClick={props.onClickCard}>
      <img src={props.data.profilePhotoUrl} alt="" />
      <div className="gradient-box"></div>
      <div class="contentBx">
        <h3 className="name">{props.data.name}</h3>
        <div className="divider"></div>
        <p className="specailization">{props.data.coverSubtitle}</p>
      </div>
    </div>
  );
}

export default PractitionerCardMobile;
