export default [
  {
    question: "Do you prescribe medication?",
    answer:
      "No, therapists are not qualified to recommend medications, but you can be referred for evaluation for medication or other treatments. ",
  },
  {
    question: "How can therapy help? What can it help with?",
    answer:
      "Therapy provides you a with safe space to explore your internal environment - your emotions, thoughts, feelings, and concerns. It enables you to identify unhealthy behavioral patterns and promote a positive change by inculcating new skills and uncovering personal strengths that enhance healthy behavior.\nWhile people usually seek therapy to treat symptoms of mental health issues like anxiety and depression, that’s not all therapy is for. It can effectively help with the following: improving your sense of self, personal relationships, emotional expression, social functioning, and coping skills. ",
  },
  {
    question: "How do I know that therapy is right for me?",
    answer:
      "Everyone can benefit from therapy - if you wish to improve your quality of life but don't know where to start, therapy is the right choice! \nHowever, You should definitely consider seeking therapy if you’re facing the following issues:\nDifficulty with emotion regulation\nNot being able to perform well at work/school\nDifficulty managing relationships\nBad physical health\nLack of interest/motivation\nAddiction\nGrief",
  },
  {
    question: "Does what I talk about in therapy remain confidential?",
    answer:
      "Yes. Therapists are ethically and legally bound to keep their conversations with clients confidential - clients’ privacy is a respected aspect of therapy. ",
  },
  {
    question: "Can I end therapy whenever I want?",
    answer:
      "While the decision to stop seeking therapy is the client’s to make at the end of the day, it is recommended that you discuss it with your therapist when you feel like the time is right to quit. This is because there will come a time when you feel empowered enough to navigate through life on your own, but it can depend on a number of factors.",
  },
  {
    question: "What will people think if they find out I take therapy?",
    answer:
      "If you’re afraid of being judged, we understand. It’s often hard to deal with the pressure of facing mental health issues that aren’t even talked about, let alone seeking help for them. Still, we’d motivate you to consider trying it out and see how it works out.",
  },
  {
    question:
      "How is a therapeutic relationship different from other relationships?",
    answer:
      "A therapeutic relationship’s purpose is to assist a client in therapy to help change their life for the better. Because of its intimate nature, therapy provides an open, safe, and nonjudgemental space to the clients so they can be at ease. While empathy and authenticity are some crucial human elements that the therapists display, therapy is a professional setup with clear-cut boundaries.",
  },
  {
    question: "What if I don't want to move on from therapy?",
    answer:
      "If you feel unfulfilled from therapy you can always go back after ending it. However, if you find it hard to function without taking therapy regularly and are finding it tough to move on, you can discuss this concern with your therapist. The role of therapy is to promote autonomy and not foster dependency on the therapist. ",
  },

  {
    question: "Can only certain ‘types’ of people seek therapy?",
    answer:
      "Anyone facing issues with their mental or physical health can seek therapy.",
  },
  {
    question: "What if my therapist dislikes/judges me?",
    answer:
      "Therapists are trained to be non-judgemental and supportive in their approach and passing personal opinions/judgments is strictly prohibited and not at all a part of the therapeutic relationship. ",
  },
  {
    question: "Can I have romantic/sexual relations with my therapist?",
    answer:
      "No, therapists are not allowed to have any kind of personal relationships with their clients, especially those of a romantic or sexual nature. Sexual contact of any kind between a client and a therapist is considered an ethical violation.",
  },
  {
    question: "Why not just take medications?",
    answer:
      "While medication can definitely help with your mental health concerns by regulating your brain chemicals, it is not the only way of treatment. Therapy is usually seen to show long-term effects as opposed to medication. Often, both are used together to attain the best results.",
  },
  {
    question: "How long will I have to take therapy?",
    answer:
      "There’s no set duration for therapy, as it depends from person to person. Everyone has different issues that they seek therapy for and can take their own time to benefit from therapy. ",
  },
  {
    question: "Isn’t therapy just paid advice?",
    answer:
      "Therapy uses evidence-based techniques to help clients deal with mental health issues. It is backed by science and has no element of personal opinions/experiences.",
  },
  {
    question: "What am I supposed to talk about in therapy?",
    answer:
      "If you are at a loss for words, you can start with whatever is causing problems in your life currently, or why did you want to seek therapy in the first place. There’s no ‘wrong’ or ‘right’ to talk about - you can be as open as you wish to. Don't worry, as you start speaking, the conversation will start to flow and probably jump to different areas of life, or even to the past or future. Your therapist will help you in the process by posing questions.",
  },
  {
    question: "What if there are certain things I don’t wish to talk about?",
    answer:
      "If there are certain things you are uncomfortable discussing, it is your choice to take your time until you feel safe enough with your therapist to open up about them. ",
  },
  {
    question: "How often do I need to visit my therapist?",
    answer:
      "It is advised to visit your therapist once a week or every other week if you have just started out. You can discuss this in therapy. Once you become consistent and start seeing progress, the frequency may decrease.",
  },
  {
    question: "Will I have to talk about my childhood?",
    answer:
      "Again, that is your choice. If you find yourself lingering on the past and going back to your childhood, you can decide to talk about it (only if it feels comfortable). But it’s not a given that a therapist will always explore your childhood/past. It can also depend on their approach and boils down to whether you feel safe enough to disclose such information.",
  },
  {
    question: "Would I need to bring in a significant other/family in therapy?",
    answer:
      "Many therapists work with couples or families, and many prefer individual therapy where they often do not ask to consult family members. If it’s needed, they can seek your permission to talk to your family members in sessions. But it’s not always necessary.",
  },
  {
    question: "Can I contact my therapist out of therapy sessions?",
    answer:
      "Typically you can’t contact your therapist out of sessions because the idea is to use insights to navigate difficulties and symptoms on your own. However, you can discuss this with them beforehand for emergency situations.",
  },
  {
    question: "How are you different from a psychiatrist?",
    answer:
      "While therapists and psychiatrists both are trained in treating mental health concerns, their approach is different. Psychiatrists do so by diagnosing and prescribing medications, while therapists use talk therapy. ",
  },
  {
    question:
      "Will I be refused a job if my employer finds out that I seek therapy?",
    answer:
      "Seeking therapy is a private concern and has nothing to do with your work life. Employers cant refuse you a job if they find out that you seek therapy. But if you have been diagnosed with mental health concerns that are affecting your work life, you may discuss this issue with your employer. ",
  },
  {
    question:
      "When should I seek a psychiatrist vs when should I seek a psychologist?",
    answer:
      "Psychiatrists and Psychologists often refer patients to one another. But, if you wish to open up and talk about your personal issues you can consult a therapist. If you are more inclined toward taking medication to treat difficult symptoms you can consult a psychiatrist. Research shows that a mix of both works best for severe mental health concerns. ",
  },
  {
    question:
      "Are therapists flexible to negotiate the price of therapy sessions?",
    answer:
      "Usually, this is not the case as therapists often have a fixed price they charge according to the time they spend on sessions. Still, this varies from therapist to therapist and you can always ask if you are in crisis and cannot afford the prices. ",
  },
  {
    question: "Does my family have to know that I am seeking therapy?",
    answer:
      "No. it's not necessary that your family knows that you are taking therapy. If you feel at ease with them knowing this, it’s purely your choice. ",
  },
  {
    question: "Can I seek therapy for a physical illness?",
    answer:
      "Physical health issues are closely linked to mental health and their symptoms can be alleviated by seeking therapy. But therapy is not the primary solution for major physical illnesses and can be used alongside other forms of treatment. ",
  },
  {
    question: "My therapist inappropriately touched me. What should I do?",
    answer:
      "If you feel signs of sexual misconduct during therapy sessions, we encourage reporting the incident to higher authorities. Touch is only allowed in therapy if it is likely to have a positive therapeutic effect, instead of making the client uncomfortable. ",
  },
  {
    question:
      "If I tell my therapist that I self-harmed. Would he/she have to tell my family members?",
    answer:
      "Therapists are obligated to report family members when a client in therapy is in impending danger, which could be a substantial risk of suicide or abusive conditions. Several mental health conditions display symptoms of self-harm coupled with suicidal ideation; many people show such symptoms but don’t always follow through and it depends on their circumstances. You can always discuss this with your therapist and see what they have assessed. ",
  },
  {
    question:
      "If my therapist ended therapy with me, does it mean they gave up on me and that I can’t be ‘fixed’?",
    answer:
      "It might not always work out with a therapist - finding a good ‘fit’ is hard even when it comes to therapy. So no, if your therapist decides to end therapy with you it likely means that they are going to refer you to other therapists that seem fit to your needs as a client. ",
  },
  {
    question:
      "I am ashamed to seek therapy for my sexual performance. Does my partner have to know?",
    answer:
      "Sexual health is linked to mental health as well as physical health. Many people seek therapy for their concerns regarding their sexual performance and it is completely okay to talk about them with your therapist.\nYour partner does not need to know if you feel insecure disclosing it. ",
  },
  {
    question: "Can therapy help to address self-esteem and anger issues?",
    answer:
      "Yes, therapy can effectively address concerns regarding one’s self-esteem and anger issues. It is a great tool to get to know yourself better and work on unhealthy thought patterns and behaviors of which such issues are a direct result. ",
  },
  {
    question: "Will couples therapy work if my loved one and I are separated?",
    answer:
      "Yes, it can definitely work if a couple is separated but also depends on what each counterpart wants out of the relationship as well as couple’s therapy.",
  },
  {
    question:
      "Is there an age limit to seeking therapy? How young or old do you have to be for therapy?",
    answer:
      "There is no specific age limit to seeking therapy. However, there are therapists trained in child and adolescent psychology. Child Psychologists cater to children aged one-two to up to the age of 13, and Adolescent Psychologists cater to those that fall in the age group of 13-18 years old. ",
  },
  {
    question: "How are you trained to be ethical?",
    answer:
      "Tatsam therapists have been trained by learned and experienced professionals through a rigorous training program with a holistic approach to therapy, with a considerable focus on ethical practice. Ethics are a crucial component of therapy and each therapist has to follow a set of ethical guidelines to safeguard their clients and promote dignity, respect, and trust within their therapeutic relationship. You can trust us to keep your personal life safe and devoid of judgments from our end.",
  },
];
