import trackEvent from "../common_functions/tract_event";

function practitioner_booking_payment_successful_event({
  orderId,
  amount,
  currency,
  description,
}) {
  trackEvent({
    eventName: "Payment Successful",
    eventDetail: {
      source: "Tatsam Website",
      orderId: orderId,

      amount: amount,

      currency: currency,

      description: description,
    },
  });
}

export default practitioner_booking_payment_successful_event;
