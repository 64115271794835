import auth0 from "auth0-js";
import axios from "axios";
import OTPInput from "otp-input-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { MdArrowBackIosNew, MdDone } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "react-phone-input-2/lib/style.css";

import { Link, useHistory } from "react-router-dom";
import RightLeaf from "../.././assets/right_flower.png";
import logo from "../../assets/logo.svg";
import mood from "../../assets/mood.png";
import { useHash, useQuery } from "../../common_functions/customHooks";
import makeUrl from "../../common_functions/makeUrl";
import login_failed_event from "../../events/login_failed_event";
import login_initiated_event from "../../events/login_initiated_event";
import user_login_event from "../../events/user_login_event";
import "./MobileNumberConfirmationScreen.css";

function MobileNumberConfirmationScreen() {
  let query = useQuery();
  let hash = useHash();
  const [counter, setCounter] = useState(59);
  const [OTP, setOTP] = useState("");
  const [validNumber, setValidNumber] = useState(true);
  const [showVarifyBox, setShowVarifyBox] = useState(false);
  const [verifyingOtp, setVerifyingOtp] = useState(false);
  const [numberVarify, setNumberVarify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phoneValidationerror, setPhoneValidationerror] = useState("");
  const [otpValidationerror, setOtpValidationerror] = useState("");
  const [num, setNum] = useState("");
  const [domain, setDomain] = useState(
    process.env.REACT_APP_TATSAM_WEB_AUTH0_DOMAIN
  );
  const [clientID, setclientID] = useState(
    process.env.REACT_APP_TATSAM_WEB_AUTH0_CLIENTID
  );
  const [redirectUri, setredirectUri] = useState(
    `${window.location.origin}/phone-verification?id=${query.get("id")}`
  );
  const [scope, setscope] = useState("openid email profile offline_access");
  const [responseType, setresponseType] = useState("token id_token");
  const [audience, setaudience] = useState(
    process.env.REACT_APP_TATSAM_WEB_AUTH0_AUDIENCE
  );

  const history = useHistory();

  const mobileNumber = `+${num}`;

  function handleOnChange(value, data, event, formattedValue) {
    setNum(value);
    if (formattedValue.length === data.format.length) {
      setValidNumber(true);
    } else {
      setValidNumber(false);
    }
  }
  function gotoBookingPage(practitioner) {
    setNumberVarify(true);
    setTimeout(function () {
      let therapyName = practitioner.name.replaceAll(" ", "-").toLowerCase();
      history.push(
        `/therapy/our-therapists/${practitioner.id}/${therapyName}/book-session`
      );
    }, 2000);
  }

  const webAuth = useRef(
    new auth0.WebAuth({
      domain: domain,
      clientID: clientID,
      redirectUri: redirectUri,
      responseType: "token id_token",
      //organization: 'org_t7oGPh45YJ6zhSBW',
      audience: audience,
    })
  );

  useEffect(() => {
    console.log("Resetting Client!!!");
    webAuth.current = new auth0.WebAuth({
      domain,
      clientID,
      redirectUri,
      scope,
      responseType,
      //organization,
      audience,
    });
  }, [
    domain,
    clientID,
    redirectUri,
    scope,
    responseType,
    //organization,
    audience,
  ]);

  useEffect(() => {
    if (showVarifyBox) {
      window.document.querySelector("input[type=tel]:nth-child(1)").focus();
    }
  }, [showVarifyBox]);
  useEffect(() => {
    if (showVarifyBox) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter, showVarifyBox]);

  function sendOtp() {
    if (!validNumber) {
      setPhoneValidationerror("Enter a valid mobile number");
      return;
    }

    login_initiated_event(mobileNumber);
    webAuth.current.passwordlessStart(
      {
        connection: "sms",
        send: "code",
        phoneNumber: mobileNumber,
      },
      function (err, result) {
        if (err) {
          console.log(err);
        } else {
          console.log(result);
        }
      }
    );

    setShowVarifyBox(true);
  }

  function verify(e) {
    if (OTP.length < 6) {
      setOtpValidationerror("Enter a valid OTP");
      return;
    }

    // reverts back the otp-validation error to empty string (if-any)
    setOtpValidationerror("");
    setVerifyingOtp(true);
    e.preventDefault();
    localStorage.setItem("mobileNumber", mobileNumber);

    webAuth.current.passwordlessLogin(
      {
        connection: "sms",
        phoneNumber: mobileNumber,
        //email: 'utkarsh@tatsam.in',
        verificationCode: OTP,
        // state: 'gJv10xx-wcMddwjJ4pXNQzRZ37JP8ZN9',
        // nonce: '1234',
      },
      function (err, result) {
        if (err) {
          console.log(err);
          setVerifyingOtp(false);
        } else {
          console.log(result);
          setVerifyingOtp(false);
        }
      }
    );
  }

  function login(userId, accessToken) {
    return axios({
      url: makeUrl("/login"),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify(userId),
    });
  }

  function fetchAuth0Details() {
    webAuth.current.parseHash(
      {
        hash: hash,
        // state: 'gJv10xx-wcMddwjJ4pXNQzRZ37JP8ZN9',
        // nonce: '1234',
      },
      function (err, authResult) {
        if (err) {
          return console.log(err);
        }

        webAuth.current.client.userInfo(
          authResult.accessToken,
          function (err, user) {
            if (user) {
              localStorage.setItem("access_token", authResult.accessToken);
              localStorage.setItem("mobile_number", user.name);
              login(user.sub, authResult.accessToken)
                .then(() => getPractitionerById(query.get("id")))
                .then((practitioner) => {
                  gotoBookingPage(practitioner);
                  console.log(user);
                  user_login_event({ phone: user.name, user: user });
                });
            } else {
              login_failed_event(err);
            }
          }
        );
      }
    );
  }

  useMemo(() => {
    if (hash && hash.includes("access_token")) {
      fetchAuth0Details();
    }
  }, [hash]);

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [OTP, num, showVarifyBox]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 2000);
  });

  const getPractitionerById = (id) => {
    return fetch(makeUrl(`/practitioners/get/${id}`)).then((responce) =>
      responce.json()
    );
  };

  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (!showVarifyBox) {
        sendOtp();
      } else {
        verify(event);
      }
    }
  };

  return (
    <section className="mobile-number-screen">
      {numberVarify ? (
        <OtpConfirmationScreen />
      ) : (
        <div className="container">
          <Link class="navbar-brand" to="/">
            <img src={logo} alt="" className="logo" />
          </Link>
          <div className="content">
            {/* Code for otp-verification loader */}
            {hash && !numberVarify ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  position: "absolute",
                  top: "37%",
                }}
              >
                <div className="loader"></div>
                <div style={{ height: "20px" }}></div>
                <p>Verifying OTP</p>
              </div>
            ) : (
              <p></p>
            )}
            {/* ------------------------------ */}

            <div className="title">
              <h1>
                {showVarifyBox && (
                  <MdArrowBackIosNew
                    onClick={() => {
                      setShowVarifyBox(false);
                      setCounter(59);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
                {hash && !numberVarify ? "" : "Phone number confirmation"}
              </h1>
            </div>
            <div
              className={!showVarifyBox ? "content-box" : "content-box active"}
            >
              {hash && !numberVarify ? (
                <p></p>
              ) : (
                <p>Please enter your phone number</p>
              )}

              {hash && !numberVarify ? (
                <p></p>
              ) : (
                <div className="text-field">
                  {/* <input
                    type="text"
                    placeholder="Enter Phone Number"
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val.length < 4 || val.length > 14) {
                        return;
                      }

                      if (val.length == 4) {
                        setNum(`+91-`);
                      }

                      const phn = val.slice(4);
                      if (isNaN(parseInt(phn))) {
                        return;
                      }

                      setNum(`+91-${parseInt(phn)}`);
                    }}
                    value={num}
                    // onKeyDown={handleKeyDown}
                  /> */}
                  <PhoneInput
                    defaultCountry={"in"}
                    country={"in"}
                    enableSearch={true}
                    value={num}
                    onChange={handleOnChange}
                  />
                </div>
              )}

              <div style={{ marginTop: "12px", minHeight: "24px" }}>
                <span className="error-msg" style={{ color: "red" }}>
                  {phoneValidationerror}
                </span>
              </div>

              {hash && !numberVarify ? (
                <p></p>
              ) : (
                <div className="button-area">
                  <a
                    className="get-started-btn"
                    onClick={() => {
                      sendOtp();
                    }}
                    style={{ fontSize: "16px" }}
                  >
                    Send verification code
                  </a>
                </div>
              )}
            </div>
            <div
              className={
                showVarifyBox ? "otp-container active" : "otp-container"
              }
            >
              <span className="opt-text">
                Please enter the verification code
              </span>
              <div style={{ marginTop: "24px" }}></div>

              <OTPInput
                value={OTP}
                onChange={setOTP}
                OTPLength={6}
                otpType="number"
                disabled={false}
              />

              <div style={{ marginTop: "12px", minHeight: "24px" }}>
                <span className="error-msg" style={{ color: "red" }}>
                  {otpValidationerror}
                </span>
              </div>

              <div style={{ marginTop: "12px" }}></div>
              <a
                className="get-started-btn"
                style={{
                  width: "187px",
                }}
                onClick={verify}
              >
                {verifyingOtp ? "Please wait ..." : "Verify number"}
              </a>
              <div style={{ marginTop: "24px" }}></div>
              {counter == 0 ? (
                <span className="resend-text">
                  Did not recieve the code?{" "}
                  <a onClick={() => sendOtp()}>Resend code</a>
                </span>
              ) : (
                <span className="resend-text">Resend OTP in 00:{counter}</span>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="right-flower">
        <img src={RightLeaf} alt="" />
      </div>
      <div className="right-cirlce"></div>
      <div className="top-cirlce"></div>
      <div className="bottom-cirlce"></div>
    </section>
  );
}

function OtpConfirmationScreen() {
  return (
    <div className="confirmation-container">
      <h1 className="awesome-text">
        Awesome!
        <img src={mood} alt="" />
      </h1>
      <h1 className="confirmation-text">Phone number confirmed</h1>
      <div className="right-icon">
        <MdDone style={{ fontSize: "50px", color: "rgba(131, 224, 221, 1)" }} />
      </div>
    </div>
  );
}

export default MobileNumberConfirmationScreen;
