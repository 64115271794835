export default [
  {
    quastion: "What is therapist session?",
    answer:
      "Our understanding sessions are designed to help filter out people who can simply help themselves by using a self-care/ self-help regimen supported by a tatsam therapist. There are many people who don’t need weekly appointments with therapists or coaches. These sessions are basically designed so that our clients don’t end up wasting money. As an organization, we have committed to expansion of access to mental health and wellbeing services. And to put our money, where our mouth is, anyone can avail of these sessions for free.",
  },
  {
    quastion: "What is therapist session?",
    answer:
      "Our understanding sessions are designed to help filter out people who can simply help themselves by using a self-care/ self-help regimen supported by a tatsam therapist. There are many people who don’t need weekly appointments with therapists or coaches. These sessions are basically designed so that our clients don’t end up wasting money. As an organization, we have committed to expansion of access to mental health and wellbeing services. And to put our money, where our mouth is, anyone can avail of these sessions for free.",
  },
  {
    quastion: "What is therapist session?",
    answer:
      "Our understanding sessions are designed to help filter out people who can simply help themselves by using a self-care/ self-help regimen supported by a tatsam therapist. There are many people who don’t need weekly appointments with therapists or coaches. These sessions are basically designed so that our clients don’t end up wasting money. As an organization, we have committed to expansion of access to mental health and wellbeing services. And to put our money, where our mouth is, anyone can avail of these sessions for free.",
  },
  {
    quastion: "What is therapist session?",
    answer:
      "Our understanding sessions are designed to help filter out people who can simply help themselves by using a self-care/ self-help regimen supported by a tatsam therapist. There are many people who don’t need weekly appointments with therapists or coaches. These sessions are basically designed so that our clients don’t end up wasting money. As an organization, we have committed to expansion of access to mental health and wellbeing services. And to put our money, where our mouth is, anyone can avail of these sessions for free.",
  },
];
