import React from "react";
import appStoreBtn from "../../../assets/appstore-btn.svg";
import playStoreBtn from "../../../assets/playstore-btn.svg";

function Evidence(props) {
  return (
    <div className="evidence" style={{ backgroundColor: `${props.bgColor}` }}>
      <div className="container">
        <div className="evidence-row">
          <div className="evidence-col">
            <span className="title">Evidence-based practices</span>
            <span className="sub-title">
              The focus is on increasing psychological capital for individuals
              and help them to reduce stress, anxiety and achieve their peak
              potential.{" "}
            </span>
          </div>
          <div className="">
            <a
              className="appStoreBtn"
              href="https://apps.apple.com/us/app/1573537207"
              target="_blank"
            >
              <img src={appStoreBtn} alt="" />
            </a>
            <a
              className="playStoreBtn"
              href="https://play.google.com/store/apps/details?id=in.tatsam.app"
              target="_blank"
            >
              <img src={playStoreBtn} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Evidence;
