import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineInstagram,
} from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import appStoreBtn from "../../../assets/appstore-btn.svg";
import footerBg from "../../../assets/footer_bg.png";
import playStoreBtn from "../../../assets/playstore-btn.svg";
import useMediaQuery from "../../../useMediaQuery";
import "./Footer.css";

function Footer() {
  let isDesktop = useMediaQuery("(min-width: 768px)");
  let isMobile = useMediaQuery("(max-width: 425px)");
  return (
    <section className="footer" style={{ backgroundImage: `url(${footerBg})` }}>
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <p className="desc">
              “Tatsam is a holistic, strategic and engaging solution which
              operates at both organisational and employee level to build
              psychological capital necessary for success and provide the right
              care at the right time to ensure optimum mental wellbeing for
              employees. ”
            </p>
          </div>
          <div className="col-md-5 d-flex flex-row-reverse link-button">
            <a
              href="https://play.google.com/store/apps/details?id=in.tatsam.app"
              target="_blank"
            >
              <img src={playStoreBtn} className="appStoreBtn" alt="" />
            </a>
            <a href="https://apps.apple.com/us/app/1573537207" target="_blank">
              <img src={appStoreBtn} className="appStoreBtn" alt="" />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 float-left">
            <div>
              <p className="title">Company</p>
              <li>
                <Link to="/" className="footer-nav-link">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/therapy" className="footer-nav-link">
                  Therapy
                </Link>
              </li>
              <li>
                <Link to="/therapy/our-therapists" className="footer-nav-link">
                  Our Therapists
                </Link>
              </li>
              <li>
                <Link to="/download" className="footer-nav-link">
                  Download
                </Link>
              </li>
            </div>
          </div>
          <div className="col-md-5 button-area">
            <p className="title">Contact Us</p>
            <a href="tel:9781179590" className="contact-btn">
              <FiPhoneCall style={{ color: "#fff", fontSize: "15px" }} />{" "}
              <span>Call</span>{" "}
            </a>
            <a
              href="https://wa.me/+919990743800"
              target="_blank"
              className="contact-btn"
            >
              <FaWhatsapp style={{ color: "#fff", fontSize: "15px" }} />{" "}
              <span>Whatsapp</span>
            </a>
            <a
              href="mailto: support@tatsam.in"
              target="_blank"
              className="contact-btn"
            >
              <MdOutlineEmail style={{ color: "#fff", fontSize: "20px" }} />{" "}
              <span>Email</span>
            </a>
            <br />
            <a
              href="https://www.linkedin.com/company/tatsam-wellness/mycompany/"
              target="_blank"
              className="socail-btn btn mt-1"
            >
              <AiFillLinkedin style={{ color: "#fff", fontSize: "20px" }} />
            </a>
            {/* <a href="" target="_blank" className="socail-bt btn mt-1">
              <AiOutlineTwitter style={{ color: "#fff", fontSize: "20px" }} />
            </a> */}
            <a
              href="https://www.instagram.com/tatsam.app/"
              target="_blank"
              className="socail-bt btn mt-1"
            >
              <AiOutlineInstagram style={{ color: "#fff", fontSize: "20px" }} />
            </a>
            <a
              href="https://www.facebook.com/tatsamapp/?locale=ne_NP"
              target="_blank"
              className="socail-bt btn mt-1"
            >
              <AiFillFacebook style={{ color: "#fff", fontSize: "20px" }} />
            </a>
            <p className="title">Registered Address</p>
            <span className="address">
              {" "}
              90b Delhi - Jaipur Expy Sector 18, Gurugram - 122001, Haryana,
              India.
            </span>
            <p className="title">Operational Address</p>
            <span className="address">
              {" "}
              5th floor Investopad 18, Institutional Area, Prem Puri, Sector 32,
              Gurugram 122001, Haryana
            </span>
          </div>
        </div>
        <div className="row">
          {isDesktop && (
            <div className="col-md-12 d-flex flex-row-reverse terms-privacy">
              <a
                target="_blank"
                href="https://support.tatsam.in/en/articles/6004150-what-is-your-cancellation-policy"
              >
                Refund & Cancellation Policies
              </a>
              |<Link to="/terms-and-conditions">Terms And Conditions</Link>|
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          )}
          {isMobile && (
            <div className="col-md-12 terms-privacy">
              <Link to="/terms-and-conditions">Terms And Conditions</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <a
                target="_blank"
                href="https://support.tatsam.in/en/articles/6004150-what-is-your-cancellation-policy"
              >
                Refund & Cancellation Policies
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Footer;
