import { Link } from "react-router-dom";
import gmail from "../../assets/gmail.png";
import logo from "../../assets/logo.svg";
import whatsapp from "../../assets/whatsapp.png";
import useMediaQuery from "../../useMediaQuery";
import "./Navbar.css";

function Navbar() {
  let isDesktop = useMediaQuery("(min-width: 1024px)");

  function handleClick(i) {
    const currentClass = document.getElementsByClassName("nav-iten-link");
    for (var j = 0; j < currentClass.length; j++) {
      if (j == i) {
        currentClass[i].classList.add("active");
      } else {
        currentClass[j].classList.remove("active");
      }
      console.log(currentClass[i].className);
    }
  }
  return (
    <div className="container">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <Link class="navbar-brand" to="/">
            <img src={logo} alt="" className="logo" />
          </Link>
          <button
            style={{ zIndex: "10" }}
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              class="navbar-nav ms-auto mb-2 mb-lg-0"
              style={{ zIndex: "10" }}
            >
              <li className="nav-item" onClick={() => handleClick(0)}>
                <Link to="/" className="nav-iten-link active">
                  Home
                </Link>
              </li>
              <li className="nav-item" onClick={() => handleClick(1)}>
                <Link to="/therapy" className="nav-iten-link">
                  Therapy
                </Link>
              </li>
              <li className="nav-item" onClick={() => handleClick(1)}>
                <Link to="/therapy/our-therapists" className="nav-iten-link">
                  Our Therapists
                </Link>
              </li>
              <li className="nav-item" onClick={() => handleClick(1)}>
                <Link to="/download" className="nav-iten-link">
                  Download
                </Link>
              </li>

              {/* <li className="nav-item" onClick={() => handleClick(1)}>
                <Link to="/therapists" className="nav-iten-link">
                  Our therapists
                </Link>
              </li> */}
              <li className="nav-item" onClick={() => handleClick(2)}>
                <a
                  href="https://wa.me/+919990743800"
                  target="_blank"
                  className="nav-iten-link"
                >
                  <img src={whatsapp} style={{ height: "25px" }} alt="" />
                </a>
              </li>
              <li className="nav-item" onClick={() => handleClick(2)}>
                <a
                  href="mailto: support@tatsam.in"
                  target="_blank"
                  className="nav-iten-link"
                >
                  <img src={gmail} style={{ height: "25px" }} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
