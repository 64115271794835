import { ImQuotesLeft } from "react-icons/im";
import { Link } from "react-router-dom";
import care1 from "../../assets/care-icon-1.png";
import care2 from "../../assets/care-icon-2.png";
import care3 from "../../assets/care-icon-3.png";
import care4 from "../../assets/care-icon-4.png";
import leftFlower from "../../assets/left_flower.png";
import leftLeaf from "../../assets/left_leaf.png";
import logo from "../../assets/logo.svg";
import rightFlower from "../../assets/right_flower.png";
import rightLeaf from "../../assets/right_leaf.png";
import topLeaf from "../../assets/top_leaf.png";
import capitalize from "../../common_functions/capitalize";
import testimonials from "../../data/testimonials";
import useMediaQuery from "../../useMediaQuery";
import Footer from "../home_page/footer/Footer";
import Navbar from "../navbar/Navbar";
import "./TherapistHomePage.css";

function TherapistHomePage() {
  const specialization = [
    "STRESS",
    "ANXIETY",
    "TRAUMA INFORMED",
    "LGBTQIA",
    "PSYCHODYNAMIC",
    "MANAGE STRESS",
    "PLAY THERAPY",
    "REDUCES ANXIETY",
    "RELATIONSHIP COUNSELLING",
    "QUEER AFFIRMATIVE",
    "EXISTENTIAL PHENOMENOLOGY",
    "EXPRESSIVE ARTS BASED THERAPY",
  ];
  let isDesktop = useMediaQuery("(min-width: 1024px)");
  let isMobileOrTablet = useMediaQuery("(max-width: 768px)");
  return (
    <section className="therapist-home-page">
      <img src={topLeaf} alt="" className="top-leaf" />
      <Navbar />
      <TherapistHeader />
      <ExpertInRangeComponent specialization={specialization} />
      <DeliverTheCareComponent />
      <OurTestiMonialsComponent testimonials={testimonials} />
      <Footer />
    </section>
  );
}

function TherapistHeader() {
  return (
    <div className="therapist-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-6" style={{ backgroud: "green" }}>
            <div className="left-content">
              <h1 className="title">Find the right therapist for you.</h1>
              <p className="sub-title">
                Whether you need support for a life situation, or just need to
                talk, our therapists are there for you.
              </p>
              <div style={{ width: "100%" }}>
                <Link
                  to="/therapy/our-therapists#therapists"
                  className="get-started-btn"
                  style={{ float: "left" }}
                >
                  Book a session
                </Link>
              </div>
              <li className="list-title">
                <span>Trained and certified</span> under{" "}
                <span>Tatsam license</span> following international guidelines
                laid down by <span>American Psychological association</span> and{" "}
                <span>NHS, UK.</span>
              </li>
              <li className="list-title">
                <span>Empathetic therapists</span> providing a{" "}
                <span>safe space</span> for everyone{" "}
                <span>without judgement.</span>
              </li>
            </div>
          </div>
          <div className="col-lg-6" style={{ backgroud: "red" }}>
            <div className="right-content">
              <div className="img-box">
                <img
                  src="https://images.tatsam.in/App+2.0+Practitioner+Photos%2FByre.jpeg"
                  alt=""
                />
              </div>
              <div className="img-box">
                <img
                  src="https://images.tatsam.in/App+2.0+Practitioner+Photos%2FChetnaLuthra_Therapist.jpeg"
                  alt=""
                />
              </div>

              <div className="img-box">
                <img
                  src="https://images.tatsam.in/App+2.0+Practitioner+Photos%2FSurabhi+Nagpal.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ExpertInRangeComponent(props) {
  function handleClick(i) {
    const currentClass = document.getElementsByClassName("specialization-pill");
    for (var j = 0; j < currentClass.length; j++) {
      if (j == i) {
        currentClass[i].classList.add("active");
      } else {
        currentClass[j].classList.remove("active");
      }
      console.log(currentClass[i].className);
    }
  }
  const specializationPill = props.specialization.map((data, index) => {
    return (
      <Link
        to="/therapy/our-therapists#therapists"
        className="specialization-pill"
        onClick={() => handleClick(index)}
      >
        {data === "LGBTQIA" ? data : capitalize(data.toLowerCase())}
      </Link>
    );
  });
  return (
    <div className="expert-range">
      <div className="right-primary-1-cirlce"></div>
      <div className="container">
        <div className="expert-range-content">
          <p className="expert-range-title">
            {" "}
            Experts in a range of specialities to meet your specific needs
          </p>
          <div className="specialization-area">{specializationPill}</div>
        </div>
      </div>
    </div>
  );
}

function DeliverTheCareComponent() {
  const Tile = (props) => {
    return (
      <div className="deliver-the-care-content-tile">
        <img src={props.img} alt="" />
        <p className="tile-title">{props.title}</p>
      </div>
    );
  };
  return (
    <div className="deliver-the-care">
      <img src={rightFlower} alt="" className="right-flower" />
      <div className="container">
        <div className="deliver-the-care-content">
          <p className="deliver-the-care-content-title">
            Our therapists deliver the care that you deserve
          </p>
          <div className="deliver-the-care-content-area">
            <Tile
              title="They will answer your every day questions, and not shoot them down"
              img={care1}
            />
            <Tile
              title="Open minded and non-judgemental experts who will focus on your needs and goals"
              img={care2}
            />
            <Tile
              title="They will help you identify effective strategies for your life situations, not just be a sounding board"
              img={care3}
            />
            <Tile
              title="They are guided by respect, compassion and your needs"
              img={care4}
            />
          </div>
        </div>
      </div>
      <div style={{ width: "100%", textAlign: "center", marginTop: "48px" }}>
        <Link
          to="/therapy/our-therapists#therapists"
          className="get-started-btn"
        >
          Book a session
        </Link>
      </div>
    </div>
  );
}

function TatsamLicenseComponent() {
  const LicenseTile = (props) => {
    return (
      <div
        className="license-tile"
        style={{ border: `1px solid ${props.borderColor}` }}
      >
        <p className="tile-title" style={{ color: `${props.color}` }}>
          {props.title}
        </p>
      </div>
    );
  };
  return (
    <section className="tatsam-license">
      <img src={rightLeaf} alt="" className="right-leaf" />
      <img src={leftLeaf} alt="" className="left-leaf" />
      <div className="container">
        <div className="tatsam-license-content">
          <h1>What is tatsam license</h1>
          <div className="tatsam-license-content-card">
            <div className="head">
              <p className="head-title">What does this mean for you?</p>
              <a class="navbar-brand">
                <img src={logo} alt="" className="head-logo" />
              </a>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <LicenseTile title="The best counselors and therapists, all under one roof." />
                <LicenseTile title="Integrated & holistic care: You get access to not just one counselor, but to the full team which supports you through every life situation - Imagine if you had your own team of counselors, yoga therapists, coaches, nutrition experts and psychiatrists to help you" />
              </div>
              <div className="col-lg-6">
                <LicenseTile title="Empathetic experts – no judgement." />
                <LicenseTile
                  title="Whatever you are going through – Relationships issues, Sleep issues, Stress, Anxiety, Depression
                 -> our therapists are trained to use the best scientific approach and support you with your everyday problems."
                />
              </div>
            </div>
          </div>
          <div
            className="tatsam-license-content-card"
            style={{ marginTop: "0px", background: "#fff" }}
          >
            <div className="head">
              <p
                className="head-title"
                style={{ color: "rgba(36, 121, 168, 1)" }}
              >
                How did we do this?
              </p>
              <a class="navbar-brand">
                <img
                  src={logo}
                  alt=""
                  style={{ filter: "brightness(1) invert(0)" }}
                  className="head-logo"
                />
              </a>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <LicenseTile
                  color="#60666C"
                  borderColor="#83E0DD"
                  title="A very senior psychology advisory panel screens, selects and trains yoga therapists and psychologists. Less than 1% of practitioners  make it successfully through the program."
                />
                <LicenseTile
                  color="#60666C"
                  borderColor="#83E0DD"
                  title="It comprises of a rigorous curriculum spread over 1 year, comprising of 2 months rigorous classroom program and 10 months of close supervision and mentorship by our advisory panel."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function OurTestiMonialsComponent(props) {
  const cards = props.testimonials.map((data, index) => {
    return (
      <div className="image-card">
        <img src={data.imgUrl} className="card-img" alt="" />
        <div className="gradient-box"></div>
        <div className="text-content">
          <p className="content">{data.content}</p>
          <p className="age">{data.age}</p>
        </div>
      </div>
    );
  });
  return (
    <div className="testimonials-section">
      <div className="right-primary-2-cirlce"></div>
      <img src={leftFlower} alt="" className="left-flower" />
      <div className="container">
        <div className="testimonials-content">
          <h4>Care which is life changing and transformative</h4>
          <p>
            After having spent countless hours in finding good therapists and
            multiple sessions with their therapists, this is what our clients
            told us before they entered.
          </p>
          <div className="testimonial-cards-area">{cards}</div>
        </div>
        <h5>After tatsam therapy, they said</h5>
        <div className="user-content-section">
          <div className="user-said-content">
            <ImQuotesLeft
              style={{ fontSize: "44px", color: "rgba(36, 121, 168, 0.7)" }}
            />
            <p className="user-said">
              “My therapist helped me change my relationship with many things
              which used to feel like constraints and now they feel like
              opportunities”
            </p>
          </div>
          <div className="user-said-content">
            <ImQuotesLeft
              style={{ fontSize: "44px", color: "rgba(36, 121, 168, 0.7)" }}
            />
            <p className="user-said">
              “I was looking for happiness in the wrong places. My therapist
              helped me learn and practice mindfulness and I have never been
              happier.”
            </p>
          </div>
          <div className="user-said-content">
            <ImQuotesLeft
              style={{ fontSize: "44px", color: "rgba(36, 121, 168, 0.7)" }}
            />
            <p className="user-said">
              “I have started to develop resilience, optimism, confidence which
              is allowing me to give my best without being bogged down by things
              which don’t matter.”
            </p>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", textAlign: "center", marginTop: "48px" }}>
        <Link
          to="/therapy/our-therapists#therapists"
          className="get-started-btn"
        >
          Book a session
        </Link>
      </div>
    </div>
  );
}
export default TherapistHomePage;
