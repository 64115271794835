import React, { useState } from "react";
import headerLeft from "../../assets/header_left.png";
import logo from "../../assets/logo.svg";
import topLeaf from "../../assets/top_leaf.png";
import Footer from "../home_page/footer/Footer";
import UnderStandingSession from "../therapist_detail_page/UnderStandingSession";
import "./BookUnderstandingPage.css";

function BookUnderstandingPage() {
  const [openPopup, setOpenPopup] = useState(false);

  function openUnderStandingPopup() {
    setOpenPopup(!openPopup);
  }
  return (
    <section className="book-understanding-page">
      <div className="container">
        <a href="index.html">
          <img src={logo} alt="" className="logo" />
        </a>
      </div>
      <div className="book-understanding-content">
        <img src={topLeaf} alt="" className="top-leaf" />
        <div className="top-left-circle"></div>
        <div className="bottom-right-circle"></div>
        <div className="container">
          <div className="row book-understanding-row">
            <div className="col-lg-6">
              <div className="left-content">
                <h1 className="heading">
                  Enter to book an understanding session
                </h1>
                <p className="title">
                  Talk to our therapist for 30 mins. Seek their help in creating
                  a daily routine for your everyday problems.
                </p>
                <p className="sub-title">
                  Many folks like you have used these understanding sessions to
                  come out of their relationship troubles, reduce stress,low
                  mood or simply find answers for everyday life situations. You
                  may just want an objective opinion on a difficult
                  relationship, need advice on a major life decision, want
                  someone to listen, or be looking for support with a mental
                  health concern.
                </p>
                <strong>
                  Whatever your reason for seeking this understanding session,
                  it is valid.
                </strong>
                <a
                  className="book-now-btn"
                  onClick={() => openUnderStandingPopup()}
                >
                  Book Now
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-content">
                <img src={headerLeft} alt="" className="header-left" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {openPopup && (
        <UnderStandingSession handleClose={openUnderStandingPopup} />
      )}
    </section>
  );
}

export default BookUnderstandingPage;
