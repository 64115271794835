import Footer from "../home_page/footer/Footer";
import Navbar from "../navbar/Navbar";
import "../privacy-policy/PrivacyPolicy.css";
function TermsAndCondition() {
  return (
    <section className="privacy-policy">
      <Navbar />
      <div className="container">
        <div className="WordSection1">
          <p
            className="MsoNormal"
            align="center"
            style={{ textAlign: "center", lineHeight: "150%" }}
          >
            <b>
              <span
                lang="EN-IN"
                style={{ fontSize: "16.0pt", lineHeight: "150%" }}
              >
                TERMS AND CONDITIONS
              </span>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "150%" }}
          >
            <span
              lang="EN-IN"
              style={{ fontSize: "14.0pt", lineHeight: "150%" }}
            >
              These Terms and Conditions including the Privacy Policy (“Terms &
              Conditions”) shall govern the mental health and wellness services
              for individuals and workplaces (“Services”) provided on the
              digital platform, which includes the website and mobile
              application of Tatsam (“Platform”, “Tatsam”, “we”, “us”, “our”).
              The Terms & Conditions is deemed to be an electronic record under
              the Information Technology Act, 2000 and the rules made
              thereunder, which does not require any digital signature. When you
              visit or access or avail the Services of this Platform, you
              (“user”, “your”, “you”, “yourself”, “organisation”) agree/sign up
              to the Terms & Conditions of this Platform. If you do not accept
              the Terms & Conditions of this Platform, then kindly refrain from
              visiting or accessing or availing any of the Services of this
              Platform.
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "150%" }}
          >
            <span
              lang="EN-IN"
              style={{ fontSize: "14.0pt", lineHeight: "150%" }}
            >
              &nbsp;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "150%" }}
          >
            <b>
              <span
                lang="EN-IN"
                style={{ fontSize: "14.0pt", lineHeight: "150%" }}
              >
                1. Information about Tatsam
              </span>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "150%" }}
          >
            <span
              lang="EN-IN"
              style={{ fontSize: "14.0pt", lineHeight: "150%" }}
            >
              The Platform is owned and controlled by PureSpace Technologies
              Private Limited, incorporated under the Companies Act, 2013,
              bearing corporate identification number U72200HR2021PTC093247,
              having registered office at 90b Delhi - Jaipur Expressway, Sector
              18, Gurugram - 122001, Haryana, India. The Platform provides
              mental health and wellness services for individuals and
              workplaces. It statistically analyses the mental health and
              provide various pathways to achieve a balanced and stress- free
              life.
            </span>
          </p>

          <p
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "150%" }}
          >
            <b>
              <span
                lang="EN-IN"
                style={{ fontSize: "14.0pt", lineHeight: "150%" }}
              >
                2. Platform’s Access
              </span>
            </b>
          </p>
          <p
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "150%" }}
          >
            <span
              lang="EN-IN"
              style={{ fontSize: "14.0pt", lineHeight: "150%", color: "black" }}
            >
              By accessing or availing the Services on the Platform, you agree
              to abide by the Terms Conditions of this Platform. In case you do
              not agree to any of the provisions of the Terms Conditions, then
              you may refrain from accessing or availing the Services of this
              Platform. It should be noted that a legal agreement is constituted
              between you and the Platform, when you access or avail the
              Services of this Platform. In case, the Platform is not accessible
              to you, at any time, then we shall not be liable to entertain any
              such claims or issues, due to such crash of the Platform. We may
              also terminate or amend the Services that are being provided to
              you, subject to your actions and authenticity of Information on
              the Platform.
            </span>
          </p>
          <ListData
            title="3. Membership:"
            subTitle="3.1. In order to avail the Services of the Platform, you warrant that:
      3.1.1. You have the legal capacity to enter into agreement;
      3.1.2. You will provide all accurate personal information (“Information”) about yourself;
      3.1.3. You will maintain the accuracy of the details provided by you on the Platform; and
      3.1.4. You shall not resort to illegal activities on the Platform.
3.2. The Services on the Platform can be availed either by individuals or the employees of
organisations, as follows:

     3.2.1. Membership for Individuals
            a. If you wish to avail the Services of this Platform, you may set up an account
               by providing your name, mobile number and email id.
               Preliminary draft for discussion purposes
           b. Once you register yourself on the Platform, you may choose various pathways
               to achieve a balanced and stress-free life, either by consulting a practitioner,
               or resorting to in-app relief programmes.

          c. We will perform a wellness audit of the employees of organisation by sending
             a list of questionnaires, which the employees will have to respond within 2
             (two) days and submit on the Platform itself. We will provide a statistical
             analysis of the mental health of each employee based on their answers to the
             questionnaires, and will recommend the relief programme to achieve a
            balanced and stress-free life.


   3.3. You agree that you will not share your username and password with anyone and if there
         is any loss or damage occurring to you with respect to password security, then we shall
         not be liable for such loss or damage.
  3.4. We shall also not be liable for any losses or damages to you, that may occur from any
         breach on our Platform."
          />
          <ListData
            title="4. Representations and Warranties"
            subTitle="4.1. You, or on behalf of whom you are visiting or accessing or availing the Services of this
Platform, agree to abide by the Terms & Conditions of this Platform.
4.2. You represent that all the Information provided by you is correct and true, and if there is
any amendment to such Information, you shall inform us from time to time.
4.3. You shall not misuse, display, publish, transmit, or access any other person’s Information
on the Platform. You shall accord to the Terms & Conditions of this Platform, and it is
your responsibility to check for any amendments or alterations to the Terms &
Conditions of this Platform.
4.4. In case, we find your involvement in any illegal usage of Information of any user, in
connection to the Terms & Conditions of this Platform, we shall have the sole discretion
to terminate your Services from the Platform and additionally, you shall be liable as per
the applicable laws of India.
4.5. We shall not entertain any claim regarding suspension or termination of your Services on
the Platform.
4.6. We shall not provide your Information to any third party, not involved in providing
Services on this Platform. However, we may disclose your Information, if required by
any government department or authority as per the applicable laws of India."
          />
          <ListData
            title="5. Intellectual Property Rights"
            subTitle="5.1. All the contents on our Platform like graphics, logo, software, videos, materials etc. have
copyright, which is either licensed to us by third party or it is owned by us. You cannot
use our licenses or reproduce them anywhere, without our prior written permission.
5.2. We provide limited access to use our Information as provided on our Platform. You shall
access the information and download the same for your own purpose, but other than that,
you cannot change or delete our trademark, copyright symbol, or other proprietary
details.
5.3. If you use our trademark, copyright symbol, or other proprietary details of us, it shall
constitute the infringement of our intellectual property rights, for which, you shall be
liable to indemnify us."
          />
          <ListData
            title="6. Limitation of Liability and Disclaimer"
            subTitle="6.1. The Services provided on our Platform does not provide any type of medical advice.
6.2. We are only providing pathways to make your life stress free by providing various
Services on our Platform, and we are not guaranteeing that such Services will provide a
therapeutic or physical benefit.
6.3. We shall not be liable for any loss, or profit etc. that occurs to its users, in connection to
the Services of the Platform and the obligations under this Terms & Conditions. In case,
the Platform is down or not working, at any time, then we shall not be liable to entertain
any such claims or issues, due to such crash of the Platform.
6.4. You agree that you shall not engage in cheating, money-laundering, getting multiple
memberships, spamming, and collusion, else you may be liable for such unlawful
conduct.
6.5. You agree that we shall not be liable for any illegal or offensive conduct of any third
party on our Platform. Further, we shall not be liable for any delay or inaccuracy in
Information provided by any third party on our Platform."
          />
          <ListData
            title="7. Indemnity"
            subTitle="7.1. You agree that you shall indemnify us from any loss, liabilities, damages that may arise
due to your failure to provide us the correct Information. If you fail to comply with the
Terms & Conditions or any breach in representation, then you shall be liable to
indemnify us wholly."
          />
          <ListData
            title="8. Governing Law and Dispute Resolution"
            subTitle="8.1. The Terms & Conditions shall be governed by, and construed in accordance with the
Laws of India and the courts of Gurugram (Haryana), India shall have exclusive
jurisdiction.
8.2. In the event of disputes, controversies, difference of opinion and claims arising out of or
in connection with this Terms & Conditions, or in any way relating hereto or any term,
condition or provision herein mentioned or the construction or interpretation thereof or
otherwise in relation hereto, the same shall be resolved by a sole arbitrator, to be
appointed by us and you.
Preliminary draft for discussion purposes
8.3. The official language of the arbitration proceedings shall be English, and the same shall
be governed by the provisions of Arbitration and Conciliation Act, 1996, and the rules
thereunder, as amended from time to time. The seat of the said arbitration proceedings,
shall be at Gurugram (Haryana), India and the award passed by the arbitral tribunal shall
be final and binding."
          />
          <ListData
            title="9. Amendment to the Terms &  Conditions"
            subTitle="9.1. We may amend or alter the Terms & Conditions from time to time. Any changes to this
Terms  & Conditions shall become effective upon posting of the revised Terms &
Conditions on the Platform, and no communication of such alteration, amendment or
update shall be made to you. It is recommended that you regularly check this Terms &
Conditions to apprise yourself of any updates."
          />
          <ListData
            title="10. Cancellation of Services"
            subTitle="10.1. Kindly note that we reserve the right to cancel or accept your membership on our
Platform.
10.2. We may cancel your membership due to your fraudulent activities, unauthorized copy or
distribution of videos or materials available on our Platform, inaccuracy or any error in
the Information provided by you, etc.
10.3. If you have any questions or concerns relating to the Cancellation, kindly email us at: support@tatsam.in"
          />
          <ListData
            title="11. Miscellaneous"
            subTitle="11.1. The Terms & Conditions shall not create any partnership or agency between you and us.
11.2. You and us agree that in addition to the damages, either of us shall be liable for specific
performance, injunction, restraining order or such other relief.
11.3. Each obligation is separate from the other obligations of this Terms & Conditions. If one
or more of the obligations contained in these Terms & Conditions is held invalid, illegal
or unenforceable in any respect by any court of competent jurisdiction, such holding will
not impair the validity, legality, or enforceability of the remaining obligations.
11.4. Time is of utmost importance for all the matters relating to the Terms &  Conditions.
11.5. The time when you access the Platform, the Terms & Conditions shall stand duly
executed between you and us."
          />

          <p>
            These Terms & Conditions are effective and updated as on 1st March
            2021
          </p>
        </div>
      </div>
      <Footer />
    </section>
  );
}

function ListData(props) {
  return (
    <div>
      <p
        className="MsoListParagraphCxSpFirst"
        style={{
          marginTop: ".3in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
          background: "white",
        }}
      >
        <span
          style={{
            fontSize: "14.0pt",
            lineHeight: "150%",
            fontFamily: "Symbol",
            color: "black",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
      </p>

      <p
        className="MsoNormal"
        style={{ textAlign: "justify", lineHeight: "150%" }}
      >
        <b>
          <span lang="EN-IN" style={{ fontSize: "14.0pt", lineHeight: "150%" }}>
            {props.title}
          </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", lineHeight: "150%" }}
      >
        <span
          lang="EN-IN"
          style={{ fontSize: "14.0pt", lineHeight: "150%", color: "black" }}
        >
          {props.subTitle}
        </span>
      </p>
      <p
        className="MsoListParagraphCxSpFirst"
        style={{
          marginTop: ".3in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: "1.0in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "150%",
          background: "white",
        }}
      >
        <span
          style={{
            fontSize: "14.0pt",
            lineHeight: "150%",
            fontFamily: "Symbol",
            color: "black",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
      </p>
    </div>
  );
}
export default TermsAndCondition;
