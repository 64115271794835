import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import leaf4 from "../../assets/leaf_4.png";
import logo from "../../assets/logo.svg";
import rightCircle from "../../assets/right_circle.png";
import rightFlower from "../../assets/right_flower.png";
import { useHash } from "../../common_functions/customHooks";
import makeUrl from "../../common_functions/makeUrl";
import shuffle from "../../common_functions/shuffle";
import therapistHeaderData from "../../data/therapist_header";
import useMediaQuery from "../../useMediaQuery";
import Evidence from "../home_page/header/Evidence";
import Navbar from "../navbar/Navbar";
import PractitionerCard from "../react_component/PractitionerCard";
import PractitionerCardMobile from "../react_component/PractitionerCardMobile";
import TherapistVideoPopup from "../react_component/TherapistVideoPopup";
import FAQ from "./FAQ";
import "./TherapistsPage.css";

function TherapistsPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [practitioner, setPractitioner] = useState([]);
  const [tempPractitioner, setTempPractitioner] = useState();
  const [categoryPractitioner, setCategoryPractitioner] = useState([]);
  const [loading, setLoading] = useState(true);
  const therapistsSectionRef = useRef(null);
  const hash = useHash();

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useHistory();
  const toDetailPage = (id, name) => {
    let therapyName = name.replaceAll(" ", "-").toLowerCase();
    navigate.push(`/therapy/our-therapists/${id}/${therapyName}`);
  };
  let isDesktop = useMediaQuery("(min-width: 1024px)");
  let isMobileOrTablet = useMediaQuery("(max-width: 768px)");

  navigate.location.pathname == "therapy/our-therapists" &&
    console.log("previous page");

  const categories = ["ALL THERAPISTS"];
  for (var i = 0; i < categoryPractitioner.length; i++) {
    categoryPractitioner[i].specializations.map((data, index) => {
      categories.push(data.displayName);
    });
  }
  function removeDuplicates(categories) {
    return categories.filter(
      (item, index) => categories.indexOf(item) === index
    );
  }

  const newCategories = removeDuplicates(categories);

  function handleClick(i) {
    const currentClass = document.getElementsByClassName("category");
    for (var j = 0; j < currentClass.length; j++) {
      if (j == i) {
        currentClass[i].classList.add("active");
      } else {
        currentClass[j].classList.remove("active");
      }
      console.log(currentClass[i].className);
    }
  }
  const categoryData = newCategories.map((data, index) => {
    return (
      <p
        className={index === 0 ? "category active" : "category"}
        onClick={() => {
          if (index === 0) {
            setPractitioner(tempPractitioner);
            handleClick(index);
          } else {
            filteredPractitioner(data);
            handleClick(index);
          }
        }}
      >
        {data}
      </p>
    );
  });

  const therapistHeaderComponent = therapistHeaderData.map((data, index) => {
    return <li className="header-tile">{data.answer}</li>;
  });

  useEffect(() => {
    fetchAllPractitioner();
  }, [0]);

  function fetchAllPractitioner() {
    fetch(makeUrl(`/practitioners/${process.env.REACT_APP_TATSAM_CATEGORY_ID}`))
      .then((responce) => responce.json())
      .then((data) => {
        setTempPractitioner(data);
        setPractitioner(shuffle(data));
        setCategoryPractitioner(data);
        setLoading(false);
      });
  }
  function filteredPractitioner(value) {
    const practData = [];
    for (var i = 0; i < categoryPractitioner.length; i++) {
      for (var j = 0; j < categoryPractitioner[i].specializations.length; j++) {
        if (
          categoryPractitioner[i].specializations[
            j
          ].displayName.toLowerCase() == value.toLowerCase()
        ) {
          practData.push(categoryPractitioner[i]);
        }
      }
    }
    setPractitioner(practData);
  }

  useEffect(() => {
    console.log({
      hash,
      therapistsSectionRef,
    });
    if (hash === "#therapists" && therapistsSectionRef.current) {
      console.log("Scrolling!!");
      console.log({
        offset: therapistsSectionRef.current.offsetTop,
      });
      setTimeout(
        () => window.scrollTo(0, therapistsSectionRef.current.offsetTop),
        10
      );
    }
  }, [hash, therapistsSectionRef]);

  return (
    <>
      <section className="therapist-page">
        <Evidence bgColor="rgba(62, 150, 86, 1)" />
        <Navbar />
        <div style={{ height: "16px" }}></div>
        {isDesktop && (
          <div className="therapist-page-header" id="therapists">
            <div className="container">
              <div className="row">
                <div className="col-lg-5" style={{ padding: "0 0" }}>
                  <div
                    className="left-box"
                    style={{ backgroundImage: `url(${leaf4})` }}
                  >
                    <img src={rightFlower} alt="" className="right-img" />
                    <img src={rightCircle} alt="" className="right-circle" />
                    <div className="left-box-content">
                      <h3>Experience</h3>
                      <img src={logo} className="logo" alt="" />
                      <p>Therapy</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7" style={{ padding: "0 0" }}>
                  <div className="right-box">
                    <div className="bottom-circle"></div>
                    <div className="left-circle"></div>
                    <div className="right-circle"></div>
                    <div className="left-lines">
                      <div className="line1"></div>
                      <div className="line1"></div>
                      <div className="line1"></div>
                    </div>
                    <div className="top-lines">
                      <div className="line2"></div>
                      <div className="line2"></div>
                      <div className="line2"></div>
                    </div>
                    <div className="bottom-lines">
                      <div className="line3"></div>
                      <div className="line3"></div>
                      <div className="line3"></div>
                    </div>
                    <h1 className="title">Why our therapists are unique!</h1>
                    <div className="container">
                      <div className="right-box-content">
                        {therapistHeaderComponent}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isMobileOrTablet && (
          <div className="therapist-page-header">
            <div className="row">
              <div
                className="left-box"
                style={{ backgroundImage: `url(${leaf4})` }}
              >
                <img src={rightCircle} alt="" className="right-circle" />
                <div className="left-box-content">
                  <h3>Experience</h3>
                  <img src={logo} className="logo" alt="" />
                  <p>Therapy</p>
                </div>
              </div>
              <div className="right-box">
                <h1 className="title">Why our therapists are unique!</h1>
                <div className="right-box-content">
                  {therapistHeaderComponent}
                </div>
              </div>
            </div>
          </div>
        )}

        <section
          className="therapist-section"
          id="therapists"
          ref={therapistsSectionRef}
        >
          <div className="container">
            <div className="row text-center">
              <p className="title">Meet our therapists</p>
            </div>
            {isDesktop && (
              <div
                className="row"
                style={{ paddingTop: "50px", minHeight: "50vh" }}
              >
                <div className="col-md-2">{categoryData}</div>
                <div className="col-md-10 card-area">
                  {loading && (
                    <div className="loader-space">
                      <div className="loader"></div>
                    </div>
                  )}
                  {practitioner.map((data, index) => {
                    return (
                      <PractitionerCard
                        data={data}
                        tap={() => toDetailPage(data.id, data.name)}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {isMobileOrTablet && (
              <div className="row" style={{ paddingTop: "32px" }}>
                <div className="category-data">{categoryData}</div>
                <div className="mob-card-area" id="therapist">
                  {practitioner.map((data, index) => {
                    return (
                      <PractitionerCardMobile
                        data={data}
                        onClickCard={() => toDetailPage(data.id, data.name)}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            <div style={{ height: "100px" }}></div>
            <FAQ />
          </div>
        </section>
        {isOpen && <TherapistVideoPopup handleClose={togglePopup} />}
      </section>
    </>
  );
}

// function collapseComponent(){
//   return <>
//   </>
// }
export default TherapistsPage;
