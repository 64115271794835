import { IoIosArrowDown } from "react-icons/io";
import generalQuastion from "../../data/general_quastion";
import paymentQuastion from "../../data/payment_quastion";
import "./FAQ.css";

function FAQ() {
  function handleClick(i) {
    const currentClass = document.getElementsByClassName("expandable-body");

    currentClass[i].classList.toggle("active");
    console.log(currentClass);
  }

  const generalQuastionComponent = generalQuastion.map((data, index) => {
    return (
      <div
        className="expandable-component"
        onClick={() => {
          console.log(index);
          handleClick(index);
        }}
      >
        <div className="expandable-header">
          <a>{data.question}</a>
          <IoIosArrowDown
            style={{
              fontSize: "30px",
              color: "rgba(96, 102, 108, 1)",
            }}
          />
        </div>
        <div className="expandable-body">
          <p> {data.answer}</p>
        </div>
      </div>
    );
  });

  const paymentQuastionComponent = paymentQuastion.map((data, i) => {
    return (
      <div
        className="expandable-component"
        onClick={() => {
          console.log(i + generalQuastion.length);
          handleClick(i + generalQuastion.length);
        }}
      >
        <div className="expandable-header">
          <a>{data.quastion}</a>
          <IoIosArrowDown
            style={{ fontSize: "30px", color: "rgba(96, 102, 108, 1)" }}
          />
        </div>
        <div className="expandable-body">
          <p> {data.answer}</p>
        </div>
      </div>
    );
  });
  return (
    <div className="row d-flex justify-content-center m-0">
      <div className="col-md-10">
        <div className="faq">
          <h1 className="faq-title">Frequently asked questions</h1>
          <div className="quastion">
            {/* <p className="sub-title">General</p> */}
            {generalQuastionComponent}
            {/* <div style={{ height: "40px" }}></div>
            <p className="sub-title">Pricing & payment</p>
            {paymentQuastionComponent} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
