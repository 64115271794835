import React from "react";
import { useHistory } from "react-router-dom";
import "./HolisticAproach.css";
import leftLeaf from "../../../assets/left_leaf.png";
import rightLeaf from "../../../assets/right_leaf.png";
import holistic from "../../../assets/holistic.png";
import superior from "../../../assets/superior.png";
import culture from "../../../assets/culture.png";
import selfManagment from "../../../assets/self-management.png";
import inclusive from "../../../assets/inclusive.png";
import useMediaQuery from "../../../useMediaQuery";
import GetStartedButton from "../../get-started-button/GetStartedButton";

function HolisticAproach() {
  const history = useHistory();
  function openPopup() {
    history.push("/download");
  }
  let isDesktop = useMediaQuery("(min-width: 1024px)");
  let isMobileOrTablet = useMediaQuery("(max-width: 768px)");
  return (
    <section className="holistic-aproach">
      <img src={leftLeaf} className="left-leaf" alt="" />
      <img src={rightLeaf} className="right-leaf" alt="" />
      <div className="container">
        <div className="heading justify-content-center">
          <h1>Our Approach</h1>
          {/* <div className="head-box">
            <p>
              Tatsam uses a proprietary approach which combines evidence-based
              practices from eastern and western wellbeing tailored specifically
              for modern working professionals. The focus is on increasing
              psychological capital for individuals and help them to reduce
              stress, anxiety and achieve their peak potential.
            </p>
          </div> */}
        </div>
      </div>
      <div className="holistic-data">
        <div className="container">
          <div className="row align-items-center align-content-center mb-4">
            <div className="col-md-6 d-flex d-flex justify-content-center">
              <div className="holistic-img">
                <img src={holistic} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6 content">
              {isDesktop && (
                <h3>
                  Holistic approach for
                  <br /> mental health
                </h3>
              )}
              {isMobileOrTablet && <h3>Holistic approach for mental health</h3>}

              <p>
                We combine the best of eastern and western wellbeing approaches
                to bring to you a care experience which is holistic in nature.
                Holistic approach is proven to result in sustainable mental
                wellbeing. Our methodology takes into account all 3 areas:
                <br />
                <br />
                1. Emotional functioning
                <br />
                2. Cognitive & behavioural functioning
                <br />
                3. Physical functioning
                <br />
                <br />
                Our exercises and practitioners target all three areas together
                to help you build psychological capital and offer the right
                support at the right time.
              </p>
            </div>
          </div>
          {/* 1 */}
          <div className="row align-items-center align-content-space-between mb-4">
            <div className="col-md-6 content">
              {isDesktop && (
                <h3>
                  A superior network of
                  <br /> care providers
                </h3>
              )}
              {isMobileOrTablet && (
                <h3>A superior network of care providers</h3>
              )}
              <p>
                Only the best senior practitioners of Psychology, Yoga, Ayurveda
                with the right skills, experience and empathy are hand-vetted
                and curated by us. They are closely supervised by our advisory
                panel to ensure that you get the best care, even if it means a
                smaller network.
                <br />
                <br />
                1. Pan-India network of specialists available in-person, live
                video and on-site precisely matched to individual’s needs
                <br />
                <br />
                2. Only trained and certified care providers - Our curated
                network undergoes strict one-on-one evaluation, training and
                certification with only 1% of providers making the cut.
                <br />
                <br />
                3. Measurable outcomes through evidence based treatments
              </p>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              <div className="holistic-img">
                <img src={superior} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
          {/* 2 */}
          <div className="row align-items-center align-content-center mb-4">
            <div className="col-md-6 d-flex justify-content-center">
              <div className="holistic-img">
                <img src={culture} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6 content">
              {isDesktop && (
                <h3>
                  Culture-affirmation is
                  <br /> better science
                </h3>
              )}
              {isMobileOrTablet && (
                <h3>Culture-affirmation is better science</h3>
              )}
              <p>
                Most of the research in mental health and wellbeing has been
                done in western societies. Our solutions take into account the
                heterogeneity of context that the individual operates in
                (culture and society). Our pathway recommendations and our
                practitioners take a careful view of different cultures and
                mindfully apply culturally competent concepts.
              </p>
            </div>
          </div>
          {/* 3 */}
          <div className="row align-items-center align-content-center mb-4">
            <div className="col-md-6 content">
              {isDesktop && (
                <h3>
                  Self management activities
                  <br /> for proactive care
                </h3>
              )}
              {isMobileOrTablet && (
                <h3>Self management activities for proactive care</h3>
              )}
              <p>
                You can take control of your feelings and thoughts by doing
                activities developed by leading experts in evidence-based
                techniques. Providing mental wellbeing improvement through
                clinically valid and evidence-based activities, ours is the
                first mobile app of its kind in Asia.
                <br />
                <br />
                You will find clinically valid activities from the following
                fields
                <br />
                <br />
                1. Positive psychology
                <br />
                2. Cognitive Behavioural Therapy
                <br />
                3. Dialectical Behavioural Therapy
                <br />
                4. Yoga
                <br />
                5. Mindfulness
              </p>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              <div className="holistic-img">
                <img src={selfManagment} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
          {/* 4 */}
          <div className="row align-items-center align-content-center mb-4">
            <div className="col-md-6 d-flex justify-content-center">
              <div className="holistic-img">
                <img src={inclusive} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6 content">
              {isDesktop && (
                <h3>
                  Inclusive community
                  <br /> led approach
                </h3>
              )}
              {isMobileOrTablet && <h3>Inclusive community led approach</h3>}
              <p>
                The Tatsam community leverages the power of inclusion and
                belonging to improve mental wellbeing. Members benefit from a
                safe and non-judgemental space where they can share experiences,
                connections, and support. Our members enjoy front-row seats to
                live sessions, podcasts and talks with business leaders and
                experts with real-world lived experiences.
              </p>
            </div>
          </div>
          {/* 5 */}
        </div>
      </div>
      <div className="holistic-data-mob">
        <div className="container">
          <div className="row align-items-center align-content-center">
            <div className="col-md-6">
              <div className="holistic-img">
                <img src={holistic} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6 content">
              <h3>Holistic approach for mental health</h3>
              <p>
                We combine the best of eastern and western wellbeing approaches
                to bring to you a care experience which is holistic in nature.
                Holistic approach is proven to result in sustainable mental
                wellbeing. Our methodology takes into account all 3 areas:
                <br />
                <br />
                1. Emotional functioning
                <br />
                2. Cognitive & behavioural functioning
                <br />
                3. Physical functioning
                <br />
                <br />
                Our exercises and practitioners target all three areas together
                to help you build psychological capital and offer the right
                support at the right time.
              </p>
            </div>
          </div>
          {/* 1 */}
          <div className="row align-items-center align-content-center">
            <div className="col-md-6">
              <div className="holistic-img">
                <img src={superior} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6 content">
              <h3>A superior network of care providers</h3>
              <p>
                Only the best senior practitioners of Psychology, Yoga, Ayurveda
                with the right skills, experience and empathy are hand-vetted
                and curated by us. They are closely supervised by our advisory
                panel to ensure that you get the best care, even if it means a
                smaller network.
                <br />
                <br />
                1. Pan-India network of specialists available in-person, live
                video and on-site precisely matched to individual’s needs
                <br />
                <br />
                2. Only trained and certified care providers - Our curated
                network undergoes strict one-on-one evaluation, training and
                certification with only 1% of providers making the cut.
                <br />
                <br />
                3. Measurable outcomes through evidence based treatments
              </p>
            </div>
          </div>
          {/* 2 */}
          <div className="row align-items-center align-content-center">
            <div className="col-md-6">
              <div className="holistic-img">
                <img src={culture} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6 content">
              <h3>Culture-affirmation is better science</h3>
              <p>
                Most of the research in mental health and wellbeing has been
                done in western societies. Our solutions take into account the
                heterogeneity of context that the individual operates in
                (culture and society). Our pathway recommendations and our
                practitioners take a careful view of different cultures and
                mindfully apply culturally competent concepts.
              </p>
            </div>
          </div>
          {/* 3 */}
          <div className="row align-items-center align-content-center">
            <div className="col-md-6">
              <div className="holistic-img">
                <img src={selfManagment} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6 content">
              <h3>Self management activities for proactive care</h3>
              <p>
                You can take control of your feelings and thoughts by doing
                activities developed by leading experts in evidence-based
                techniques. Providing mental wellbeing improvement through
                clinically valid and evidence-based activities, ours is the
                first mobile app of its kind in Asia.
                <br />
                <br />
                You will find clinically valid activities from the following
                fields
                <br />
                <br />
                1. Positive psychology
                <br />
                2. Cognitive Behavioural Therapy
                <br />
                3. Dialectical Behavioural Therapy
                <br />
                4. Yoga
                <br />
                5. Mindfulness
              </p>
            </div>
          </div>
          {/* 4 */}
          <div className="row align-items-center align-content-center">
            <div className="col-md-6">
              <div className="holistic-img">
                <img src={inclusive} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6 content">
              <h3>Inclusive community led approach</h3>
              <p>
                The Tatsam community leverages the power of inclusion and
                belonging to improve mental wellbeing. Members benefit from a
                safe and non-judgemental space where they can share experiences,
                connections, and support. Our members enjoy front-row seats to
                live sessions, podcasts and talks with business leaders and
                experts with real-world lived experiences.
              </p>
            </div>
            <div className="d-flex pt-5 pb-5 justify-content-center">
              <GetStartedButton onTap={openPopup} />
            </div>
          </div>
          {/* 5 */}
        </div>
      </div>
    </section>
  );
}

export default HolisticAproach;
